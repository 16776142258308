import React, { useState } from "react";

export const Finish = ({ changeScreen, i18n }) => {
  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block gradient-finish finish-screen scroll-y">
        <div className="simple-text">
          <img src="/images/find-waldo/party-popper_60.svg" alt="" />
          <div>{i18n.t("waldogame:Finish.text1")}</div>
        </div>
        <p>{i18n.t("waldogame:Finish.text2")}</p>
      </div>
    </div>
  );
};
