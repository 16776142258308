import React from "react";
import classNames from "classnames";

import "./colored-button.scss";
function ColoredButton({
  className = "",
  theme = "green",
  children,
  onClick,
  disabled = false,
}) {
  return (
    <button
      className={classNames(
        className,
        "colored-button",
        `colored-button--theme--${theme}`,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="colored-button__font font--title">{children}</span>
    </button>
  );
}

export default ColoredButton;
