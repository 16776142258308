import React, { useState } from "react";
import { NextWiget } from "../NextWiget";

export const Screen_2 = ({ changeScreen, i18n }) => {
  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block gradient-type-2 post-letter">
        <div className="post-letter__head">
          {i18n.t("waldogame:Screen2.title")}
        </div>
        <div className="post-letter__postcard-block">
          <div className="postcard-block-content">
            <div className="postcard-block-content-head">
              <img
                src="/images/find-waldo/stamp.svg"
                className="stamp-img"
                alt=""
              />
              <img
                src="/images/find-waldo/postcard.svg"
                alt=""
                className="content-head-title"
              />
              <img
                src="/images/find-waldo/stamp2.svg"
                className="stamp-img2"
                alt=""
              />
            </div>
            <div className="postcard-block-content-text">
              <p>{i18n.t("waldogame:Screen2.text1")}</p>
              <span></span>
              <p>{i18n.t("waldogame:Screen2.text2")}</p>
              <br />
              <p>{i18n.t("waldogame:Screen2.text3")}</p>
            </div>
          </div>
        </div>
        <NextWiget changeScreen={changeScreen} screen="Screen_3" />
      </div>
    </div>
  );
};
