import level1 from "./level1";
import level2 from "./level2";
import level3 from "./level3";

export default [
  { type: "level", value: 1 },
  ...level1,
  { type: "level", value: 2 },
  ...level2,
  { type: "level", value: 3 },
  ...level3,
  { type: "thanks" },
];
