import { useContext, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

import scenarios from "../scenarios";
import settings from "../config/settings";
import callWithDelay from "../../../helpers/callWithDelay";

import { LangContext } from "../../../App";
import { useCookies } from "react-cookie";

function useProveMeWrong() {
  const i18n = useContext(LangContext);

  const [isResult, setIsResult] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["proveLevel"]);

  const [points, setPoints] = useState(0);
  const [chatItems, setChatItems] = useState([]);

  const [level, setLevel] = useState(settings.INITIAL_LEVEL);
  const [currentStage, setCurrentStage] = useState(1);

  const [showResponses, setShowResponses] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({});

  const containerRef = useRef(null);

  // Common
  const addItemToChat = (type, payload) => {
    const newItem = {
      id: v4(),
      type,
      ...payload,
    };

    setChatItems((value) => [...value, newItem]);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollToBottom();
    }
  }, [chatItems, showResponses]);

  const clearChat = () => {
    setChatItems([]);
  };

  // Level
  const changeLevel = (value) => {
    setPoints(0);
    setLevel(value);
    setCookie("proveLevel", value, { maxAge: 600 });

    clearChat();

    callWithDelay(() => addItemToChat("level", { value }));

    callWithDelay(() => changeStage(1), "long");
  };

  const finishLevel = () => {
    closePopup();

    const nextLevel = level + 1 <= scenarios.length ? level + 1 : undefined;

    if (nextLevel) {
      changeLevel(nextLevel);
    } else {
      setIsResult(true);
    }
  };

  const changeStage = (id) => {
    setCurrentStage(id);

    let stageData;

    setLevel((value) => {
      stageData = scenarios[value - 1][id];

      return value;
    });

    if (stageData.text) {
      addItemToChat("message", {
        data: {
          isSent: false,
          text: stageData.text,
          link: stageData.link,
          imageSrc: stageData.imageSrc,
        },
      });
    }

    if (stageData.sharing) {
      callWithDelay(
        () =>
          addItemToChat("message", {
            data: {
              isSent: false,
              text: stageData.sharing.text,
              link: stageData.sharing.link,
              imageSrc: stageData.sharing.imageSrc,
            },
          }),
        "short",
      );
    }

    if (stageData.restart) {
      openPopup({
        text: i18n.t(stageData.restart.text),
        buttonText: i18n.t(stageData.restart.buttonText),
        color: "pink",
        iconType: "sad",
        onClick: () => restartStage(stageData.restart.id),
      });
    } else if (stageData.isFinal) {
      let popupText;

      if (!stageData.firstAchievement && !stageData.secondAchievement) {
        popupText = `provemewrong:levels.${level}.achievement`;
      }

      if (
        stageData.secondAchievement &&
        stageData.secondAchievement.min &&
        points >= stageData.secondAchievement.min
      ) {
        popupText = `provemewrong:levels.${level}.achievement2`;
      } else if (stageData.firstAchievement) {
        popupText = `provemewrong:levels.${level}.achievement`;
      }

      if (popupText) {
        callWithDelay(
          () =>
            openPopup({
              text: i18n.t(popupText),
              buttonText: i18n.t("common:Buttons.next"),
              color: "green",
              iconType: "happy",
              onClick: () => finishLevel(),
            }),
          "long",
        );
      } else {
        finishLevel();
      }
    } else {
      callWithDelay(() => setShowResponses(true));
    }
  };

  const restartStage = (id) => {
    setPoints(0);
    closePopup();

    callWithDelay(() => changeStage(id), "long");
  };

  // Response
  const selectResponse = ({
    questionId,
    text,
    value,
    isFinal,
    firstAchievement,
    secondAchievement,
    withReset,
  }) => {
    setShowResponses(false);

    // Send user message
    addItemToChat("message", {
      data: {
        isSent: true,
        text: text,
      },
    });

    if (isFinal) {
      let popupText;

      if (!firstAchievement && !secondAchievement) {
        popupText = `provemewrong:levels.${level}.achievement`;
      }

      if (
        secondAchievement &&
        secondAchievement.min &&
        points >= secondAchievement.min
      ) {
        popupText = `provemewrong:levels.${level}.achievement2`;
      } else if (firstAchievement) {
        popupText = `provemewrong:levels.${level}.achievement`;
      }

      if (popupText) {
        openPopup({
          text: i18n.t(popupText),
          buttonText: i18n.t("common:Buttons.next"),
          color: "green",
          iconType: "happy",
          onClick: () => finishLevel(),
        });
      } else {
        finishLevel();
      }
    } else {
      // Show effect
      if (value !== undefined) {
        callWithDelay(() => {
          setPoints((currentPoints) => currentPoints + value);

          addItemToChat("effect", {
            value: `${i18n.t("provemewrong:effect")} ${
              value > 0 ? "+" + value : value
            }`,
          });
        }, "short");
      }

      if (withReset) {
        setPoints(0);
      }

      // Change stage/question
      callWithDelay(() => changeStage(questionId), "long");
    }
  };

  // Popups
  const openPopup = (data) => {
    setPopupData({ ...data });

    callWithDelay(() => setShowPopup(true), "long");
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    changeLevel(cookies.proveLevel || settings.INITIAL_LEVEL);
  }, []);

  return {
    isResult,

    points,
    setPoints,

    level,
    setLevel,

    currentStage,
    setCurrentStage,

    chatItems,
    setChatItems,

    responses: scenarios[level - 1][currentStage]?.answers,
    showResponses,
    setShowResponses,
    selectResponse,

    changeLevel,
    changeStage,

    showPopup,
    setShowPopup,
    popupData,

    containerRef,
  };
}

export default useProveMeWrong;
