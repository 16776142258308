import React from "react";
import classNames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Scrollbars } from "react-custom-scrollbars-2";

import "./chat-window.scss";

import MessageCard from "../cards/MessageCard";
import ChatResponses from "./ChatResponses";
import LevelCard from "../cards/LevelCard";
import EffectCard from "../cards/EffectCard";
import ProveResultPopup from "../popups/ProveResultPopup";

function ChatWindow({
  className = "",
  items,
  responses,
  i18n,
  showResponses,
  selectResponse,
  showPopup,
  popupData,
  containerRef,
}) {
  return (
    <div
      className={classNames(className, "chat-window", {
        "chat-window--responses": showResponses,
      })}
    >
      <Scrollbars
        ref={containerRef}
        className="chat-window__chat-wrapper"
        style={{ width: "100%", height: "100%" }}
      >
        <TransitionGroup className="chat-window__chat">
          {items.map((item) => {
            switch (item.type) {
              case "message":
                return (
                  <CSSTransition
                    key={item.id}
                    timeout={150}
                    classNames={item.data.isSent ? "message-out" : "message-in"}
                  >
                    <MessageCard
                      className="chat-window__message"
                      text={i18n.t(item.data.text)}
                      imageSrc={item.data.imageSrc}
                      link={item.data.link}
                      isSent={item.data.isSent}
                    />
                  </CSSTransition>
                );
              case "level":
                return (
                  <CSSTransition
                    key={item.id}
                    timeout={150}
                    classNames="message-in"
                  >
                    <LevelCard
                      className="chat-window__level"
                      level={item.value}
                    />
                  </CSSTransition>
                );

              case "effect":
                return (
                  <CSSTransition
                    key={item.id}
                    timeout={150}
                    classNames="message-in"
                  >
                    <EffectCard
                      className="chat-window__effect"
                      value={item.value}
                    />
                  </CSSTransition>
                );
            }
          })}
        </TransitionGroup>
      </Scrollbars>

      {responses && responses.length && (
        <ChatResponses
          className={classNames("chat-window__responses", {
            "chat-window__responses--show": showResponses,
          })}
          responses={responses}
          selectResponse={selectResponse}
          i18n={i18n}
        />
      )}

      {showPopup && (
        <div className="chat-window__popup-wrapper">
          <ProveResultPopup
            text={popupData.text}
            buttonText={popupData.buttonText}
            color={popupData.color}
            iconType={popupData.iconType}
            onClick={popupData.onClick}
          />
        </div>
      )}
    </div>
  );
}

export default ChatWindow;
