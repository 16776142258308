import React, { useState } from "react";
import { Screen_4 } from "./Screen_4";
import { Screen_7 } from "./Screen_7";

export const Screen_6 = ({ changeScreen, i18n }) => {
  const [animation, setAnimation] = useState(false);
  const [answer, setAnswer] = useState(false);

  const checkAnswerHandler = () => {
    setAnswer(true);
    setTimeout(() => {
      setAnimation(true);
    }, 300);
  };

  return (
    <div className={animation ? "waldo-game hidden-overflow" : "waldo-game"}>
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block default-block-wrapper">
        <div
          className={
            animation
              ? "waldo-game__default-block gradient-type-3 a-b-question animate"
              : "waldo-game__default-block gradient-type-2 a-b-question"
          }
        >
          <div className="a-b-question-block">
            <div className="a-b-question-block__head">
              {i18n.t("waldogame:Screen6.title")}
            </div>
            <div className="a-b-question-block__body">
              <div
                onClick={checkAnswerHandler}
                className={
                  answer
                    ? "question a-question right-answer answered"
                    : "question a-question right-answer"
                }
              >
                {answer ? <div className="check-circle "></div> : <div>a</div>}
                {i18n.t("waldogame:Screen6.text1")}
              </div>
              <div
                onClick={checkAnswerHandler}
                className={
                  answer
                    ? "question b-question error-answer answered"
                    : "question b-question error-answer"
                }
              >
                {answer ? <div className="error-circle"></div> : <div>b</div>}
                {i18n.t("waldogame:Screen6.text2")}
              </div>
            </div>
          </div>
        </div>
        {animation && <Screen_7 changeScreen={changeScreen} i18n={i18n} />}
      </div>
    </div>
  );
};
