import React from "react";
import classNames from "classnames";

import "./lifes-header.scss";

import ProgressLabel from "./ProgressLabel";
import LifesLabel from "./LifesLabel";

function LifesHeader({
  className = "",
  currentProgress,
  totalProgress,
  lifes,
}) {
  return (
    <div className={classNames(className, "lifes-header")}>
      <div className="lifes-header__wrapper">
        <ProgressLabel
          className="lifes-header__progress"
          current={currentProgress}
          total={totalProgress}
        />
      </div>

      <div className="lifes-header__wrapper lifes-header__wrapper--right">
        <LifesLabel className="lifes-header__points" lifes={lifes} />
      </div>
    </div>
  );
}

export default LifesHeader;
