import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProtectionScreen = ({
  changeScreen,
  gradientBottom,
  gradientTop,
  onScrollHandler,
}) => {
  const { i18n } = useTranslation();
  return (
    <div className="protection-screen">
      <div className="game-container pseudo-container">
        <div className="back-arrow">
          <Link to={`/${i18n.language}`}>
            <img
              src="/images/game1/arrow-back.svg"
              alt=""
              className="hover-scale"
            />
          </Link>
        </div>
        <div className="container-content">
          {gradientTop && <div className="gradient top-gradient"></div>}
          <div className="container-content-wrapper" onScroll={onScrollHandler}>
            <div className="container-title small-screen">Серйозно?</div>
            <img
              src="/images/take-quiz/seriously.gif"
              alt=""
              className="seriously-gif"
            />
            <p>
              Не треба так. Ми, насправді, не потребуємо доступу до вашої
              інформації, але саме так починаються усі подібні онлайн-тести, а
              ми під них мімікруємо. Але годі вже цих прелюдій, давайте нарешті
              дізнаємося яка ви поп-пісня!
            </p>
          </div>
          {gradientBottom && <div className="gradient bottom-gradient"></div>}
        </div>
        <div className="btn-wrapper">
          <div
            className="game-btn start-btn hover-scale"
            onClick={() => changeScreen("TestScreen")}
          >
            На старт
          </div>
        </div>
      </div>
    </div>
  );
};

export const FbScreen = ({
  changeScreen,
  gradientBottom,
  gradientTop,
  onScrollHandler,
}) => {
  const [protectScreen, setProtectScreen] = useState(false);
  return (
    <div className="game-container">
      <div className="fb-page-block">
        <div className="container-title">Вхід</div>
        <p>
          Щоб пройти цей тест ви маєте надати доступ до інформації зі своєї
          сторінки Facebook.
        </p>
        <div className="fake-fb-btn" onClick={() => setProtectScreen(true)}>
          <img src="/images/take-quiz/fb-icon.svg" alt="" />
          Увійти с даними facebook
        </div>
      </div>
      {protectScreen && (
        <ProtectionScreen
          gradientBottom={gradientBottom}
          gradientTop={gradientTop}
          onScrollHandler={onScrollHandler}
          changeScreen={changeScreen}
        />
      )}
    </div>
  );
};
