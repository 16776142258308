import React, { useContext } from "react";
import classNames from "classnames";

import "./round-label.scss";

import { LangContext } from "../../../../App";

function RoundLabel({ className = "", level }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "round-label")}>
      <span className="round-label__font font--title">
        {i18n.t("navigatemedia:round", {
          value: i18n.t(`navigatemedia:${level}`),
        })}
      </span>
    </div>
  );
}

export default RoundLabel;
