import React, { useContext, useState } from "react";
import { StartGame } from "./screens/StartGame";
import { GameField } from "./screens/GameField";
import { EndGame } from "./screens/EndGame";
import data from "./data";
import "./style.scss";
import { LangContext } from "../../App";
import useLocale from "../../hooks/useLocale";

const HoldBreath = ({ match }) => {
  const [screen, setScreen] = useState("StartGame");
  const [twitt, setTwitt] = useState("top"); // top - bottom
  const [animation, setAnimation] = useState(false);
  const [timer, setTimer] = useState(0);
  const [score, setScore] = useState(0);

  const changeScreen = (screen) => setScreen(screen);

  const i18n = useContext(LangContext);

  const changeTwitt = () => {
    if (twitt === "bottom") {
      setTwitt("top");
      setScore((score) => score + 1);
      setAnimation(false);
      return false;
    }
    setTwitt("bottom");
    setScore((score) => score + 1);
    setAnimation(false);
  };
  const delay = (ms) => {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  };
  const startAnimation = () => {
    setAnimation(true);
    delay(1500).then(changeTwitt);
  };

  useLocale(match.params.locale);
  const startTimer = () => {
    let timer = setInterval(() => {
      const div = document.createElement("div");
      const element = document.getElementById("timerBar");
      element.append(div);
      setTimer((timer) => timer + 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      changeScreen("EndGame");
    }, 10000);
  };

  useLocale(match.params.locale);

  return (
    <div className="game-field game">
      {screen === "StartGame" && (
        <StartGame changeScreen={changeScreen} i18n={i18n} />
      )}
      {screen === "GameField" && (
        <GameField
          i18n={i18n}
          data={data}
          score={score}
          twitt={twitt}
          timer={timer}
          animation={animation}
          startTimer={startTimer}
          startAnimation={startAnimation}
          changeScreen={changeScreen}
        />
      )}
      {screen === "EndGame" && <EndGame score={score} i18n={i18n} />}
    </div>
  );
};

export default HoldBreath;
