import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SongScreen = ({
  changeScreen,
  songs,
  gradientBottom,
  gradientTop,
  onScrollHandler,
}) => {
  const { i18n } = useTranslation();
  const [song, setSong] = useState(0);
  useEffect(() => {
    const number = Math.floor(Math.random() * songs.length);
    setSong(number);
  }, []);
  return (
    <div className="game-container">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="container-content song-screen">
        {gradientTop && <div className="gradient top-gradient"></div>}
        <div className="container-content-wrapper" onScroll={onScrollHandler}>
          <div className="container-title question-title">Вітаємо!</div>
          <div className="song-gif-wrapper">
            <img src="/images/take-quiz/song.gif" alt="" className="song-gif" />
            <p>{songs[song].songName}</p>
          </div>
          <p>{songs[song].description}</p>
        </div>
        {gradientBottom && <div className="gradient bottom-gradient"></div>}
      </div>
      <div className="btn-wrapper">
        <div
          className="game-btn start-btn hover-scale hold-breath-btn"
          onClick={() => changeScreen("SliderScreen")}
        >
          Дізнатися більше
        </div>
      </div>
    </div>
  );
};
