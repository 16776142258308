import React from "react";

import OutlinedButton from "../../ui/OutlinedButton";

export const EndGame = ({ score, i18n, onClick }) => {
  return (
    <div className="game-passwords-result">
      <div className="game-passwords-result__title">
        <span className="game-passwords-result__font game-passwords-result__font--title font--title">
          {score}/7
        </span>
      </div>

      <div className="game-passwords-result__text">
        <span className="game-passwords-result__font game-passwords-result__font--text font--text">
          {i18n.t("passgame:EndText")}
        </span>
      </div>

      <OutlinedButton
        className="game-passwords-result__button"
        color="green"
        onClick={() => onClick(true)}
      >
        {i18n.t("common:Buttons.next")}
      </OutlinedButton>
    </div>
  );
};
