import { labelsDictionary } from "../config/labels";

export default [
  {
    id: 1,
    type: "news",
    authorName: "conveyor:levels.1.news.1.authorName",
    authorNick: "conveyor:levels.1.news.1.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q1.png",
    text: "conveyor:levels.1.news.1.text",
    explanation: "conveyor:levels.1.news.1.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q1.jpg",
    labelIcon: labelsDictionary[1].icon,
    labelId: labelsDictionary[1].id,
  },
  {
    id: 2,
    type: "news",
    authorName: "conveyor:levels.1.news.2.authorName",
    authorNick: "conveyor:levels.1.news.2.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q2.jpeg",
    text: "conveyor:levels.1.news.2.text",
    explanation: "conveyor:levels.1.news.2.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q2.jpg",
    labelIcon: labelsDictionary[2].icon,
    labelId: labelsDictionary[2].id,
  },
  {
    id: 3,
    type: "news",
    authorName: "conveyor:levels.1.news.3.authorName",
    authorNick: "conveyor:levels.1.news.3.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q3.png",
    text: "conveyor:levels.1.news.3.text",
    explanation: "conveyor:levels.1.news.3.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q3.jpg",
    labelIcon: labelsDictionary[2].icon,
    labelId: labelsDictionary[2].id,
  },
  {
    id: 4,
    type: "news",
    authorName: "conveyor:levels.1.news.4.authorName",
    authorNick: "conveyor:levels.1.news.4.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q4.png",
    text: "conveyor:levels.1.news.4.text",
    explanation: "conveyor:levels.1.news.4.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q4.jpg",
    labelIcon: labelsDictionary[1].icon,
    labelId: labelsDictionary[1].id,
  },
  {
    id: 5,
    type: "news",
    authorName: "conveyor:levels.1.news.5.authorName",
    authorNick: "conveyor:levels.1.news.5.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q5.png",
    text: "conveyor:levels.1.news.5.text",
    explanation: "conveyor:levels.1.news.5.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q5.jpg",
    labelIcon: labelsDictionary[2].icon,
    labelId: labelsDictionary[2].id,
  },
  {
    id: 6,
    type: "news",
    authorName: "conveyor:levels.1.news.6.authorName",
    authorNick: "conveyor:levels.1.news.6.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q6.svg",
    text: "conveyor:levels.1.news.6.text",
    explanation: "conveyor:levels.1.news.6.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q6.jpg",
    labelIcon: labelsDictionary[1].icon,
    labelId: labelsDictionary[1].id,
  },
  {
    id: 7,
    type: "news",
    authorName: "conveyor:levels.1.news.7.authorName",
    authorNick: "conveyor:levels.1.news.7.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q7.jpg",
    text: "conveyor:levels.1.news.7.text",
    explanation: "conveyor:levels.1.news.7.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q7.jpg",
    labelIcon: labelsDictionary[2].icon,
    labelId: labelsDictionary[2].id,
  },
  {
    id: 8,
    type: "news",
    authorName: "conveyor:levels.1.news.8.authorName",
    authorNick: "conveyor:levels.1.news.8.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q8.png",
    text: "conveyor:levels.1.news.8.text",
    explanation: "conveyor:levels.1.news.8.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q8.jpg",
    labelIcon: labelsDictionary[1].icon,
    labelId: labelsDictionary[1].id,
  },
  {
    id: 9,
    type: "news",
    authorName: "conveyor:levels.1.news.9.authorName",
    authorNick: "conveyor:levels.1.news.9.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q9.avif",
    text: "conveyor:levels.1.news.9.text",
    explanation: "conveyor:levels.1.news.9.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q9.jpg",
    labelIcon: labelsDictionary[2].icon,
    labelId: labelsDictionary[2].id,
  },
  {
    id: 10,
    type: "news",
    authorName: "conveyor:levels.1.news.10.authorName",
    authorNick: "conveyor:levels.1.news.10.authorNick",
    authorImage: "/images/conveyor/author/level-1/L1Q10.jpeg",
    text: "conveyor:levels.1.news.10.text",
    explanation: "conveyor:levels.1.news.10.explanation",
    imageSrc: "/images/conveyor/news/level-1/Q10.jpg",
    labelIcon: labelsDictionary[1].icon,
    labelId: labelsDictionary[1].id,
  },
];
