import signs from "./signs";

export default [
  {
    title: "navigatemedia:situations.25.title",
    text: "navigatemedia:situations.25.text",
    answerId: signs.MEDIA_OWNER.id,
  },
  {
    title: "navigatemedia:situations.26.title",
    text: "navigatemedia:situations.26.text",
    answerId: signs.ANALYSIS.id,
  },
  {
    title: "navigatemedia:situations.27.title",
    text: "navigatemedia:situations.27.text",
    answerId: signs.HISTORY.id,
  },
  {
    title: "navigatemedia:situations.28.title",
    text: "navigatemedia:situations.28.text",
    answerId: signs.MANIPULATION.id,
  },
  {
    title: "navigatemedia:situations.29.title",
    text: "navigatemedia:situations.29.text",
    answerId: signs.CRITICAL.id,
  },
  {
    title: "navigatemedia:situations.30.title",
    text: "navigatemedia:situations.30.text",
    answerId: signs.POLITICS.id,
  },
];
