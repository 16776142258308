export default {
  1: {
    id: 1,
    text: "provemewrong:levels.2.questions.1.text",
    answers: [
      {
        questionId: 2,
        text: "provemewrong:levels.2.questions.1.answers.1",
        value: 1,
      },
      {
        questionId: 2,
        text: "provemewrong:levels.2.questions.1.answers.2",
        value: 0,
      },
    ],
  },
  2: {
    id: 2,
    text: "provemewrong:levels.2.questions.2.text",
    answers: [
      {
        questionId: 3,
        text: "provemewrong:levels.2.questions.2.answers.1",
        value: 1,
      },
      {
        questionId: 3,
        text: "provemewrong:levels.2.questions.2.answers.2",
        value: 1,
      },
    ],
  },
  3: {
    id: 3,
    text: "provemewrong:levels.2.questions.3.text",
    answers: [
      {
        questionId: 4,
        text: "provemewrong:levels.2.questions.3.answers.1",
        value: 1,
      },
      {
        questionId: 10,
        text: "provemewrong:levels.2.questions.3.answers.2",
        value: -1,
      },
    ],
  },
  4: {
    id: 4,
    text: "provemewrong:levels.2.questions.4.text",
    answers: [
      {
        questionId: 5,
        text: "provemewrong:levels.2.questions.4.answers.1",
        value: 2,
      },
      {
        questionId: 5,
        text: "provemewrong:levels.2.questions.4.answers.2",
        value: 2,
      },
    ],
  },
  5: {
    id: 5,
    text: "provemewrong:levels.2.questions.5.text",
    answers: [
      {
        questionId: 6,
        text: "provemewrong:levels.2.questions.5.answers.1",
        value: 1,
      },
      {
        questionId: 10,
        text: "provemewrong:levels.2.questions.5.answers.2",
        value: 0,
      },
    ],
  },
  6: {
    id: 6,
    text: "provemewrong:levels.2.questions.6.text",
    answers: [
      {
        questionId: 7,
        text: "provemewrong:levels.2.questions.6.answers.1",
        value: 1,
      },
      {
        questionId: 7,
        text: "provemewrong:levels.2.questions.6.answers.2",
        value: 0,
      },
    ],
  },
  7: {
    id: 7,
    text: "provemewrong:levels.2.questions.7.text",
    answers: [
      {
        questionId: 8,
        text: "provemewrong:levels.2.questions.7.answers.1",
        value: 1,
      },
    ],
  },
  8: {
    id: 8,
    text: "provemewrong:levels.2.questions.8.text",
    answers: [
      {
        questionId: 9,
        text: "provemewrong:levels.2.questions.8.answers.1",
        value: 1,
      },
      {
        questionId: 10,
        text: "provemewrong:levels.2.questions.8.answers.2",
        value: -1,
      },
    ],
  },
  9: {
    id: 9,
    text: "provemewrong:levels.2.questions.9.text",
    answers: [
      {
        questionId: 14,
        text: "provemewrong:levels.2.questions.9.answers.1",
        value: 1,
      },
    ],
  },
  10: {
    id: 10,
    text: "provemewrong:levels.2.questions.10.text",
    answers: [
      {
        questionId: 4,
        text: "provemewrong:levels.2.questions.10.answers.1",
        value: 1,
      },
      {
        questionId: 11,
        text: "provemewrong:levels.2.questions.10.answers.2",
        value: -1,
      },
    ],
  },
  11: {
    id: 11,
    text: "provemewrong:levels.2.questions.11.text",
    answers: [
      {
        questionId: 3,
        text: "provemewrong:levels.2.questions.11.answers.1",
        value: 1,
      },
      {
        questionId: 12,
        text: "provemewrong:levels.2.questions.11.answers.2",
        value: -2,
      },
    ],
  },
  12: {
    id: 12,
    text: "provemewrong:levels.2.questions.12.text",
    answers: [
      {
        questionId: 1,
        text: "provemewrong:levels.2.questions.12.answers.1",
        value: 0,
        withReset: true,
      },
      {
        questionId: 13,
        text: "provemewrong:levels.2.questions.12.answers.2",
        value: 0,
      },
    ],
  },
  13: {
    id: 13,
    text: "provemewrong:levels.2.questions.13.text",
    answers: [
      {
        questionId: 1,
        text: "provemewrong:levels.2.questions.13.answers.1",
        withReset: true,
      },
    ],
  },
  14: {
    id: 14,
    text: "provemewrong:levels.2.questions.14.text",
    isFinal: true,
    secondAchievement: {
      min: 12,
    },
    firstAchievement: {
      max: -1,
    },
  },
};
