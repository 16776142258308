import React, { useEffect } from "react";
import classNames from "classnames";

import "./layout-default.scss";
import Header from "../components/layout/Header";
import ReloadButton from "../components/ui/ReloadButton.jsx";

function Default({ className = "", children, isHidden = false, title }) {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, []);
  return (
    <div className={classNames(className, "layout-default")}>
      <Header className="layout-default__header" />

      <div
        className={classNames("layout-default__content", {
          "layout-default__content--hidden": isHidden,
        })}
      >
        {children}
      </div>

      <ReloadButton className="layout-default__button" />
    </div>
  );
}

export default Default;
