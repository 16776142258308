import React, {useState} from "react"
import {NextWiget} from "../NextWiget";
import {Prompt} from "../Prompt";

export const Screen_23 = ({changeScreen, i18n}) => {
    const [prompt, setPrompt] = useState(false)
    const [link, setLink] = useState(false)

    return(
        <div className="waldo-game__default-block no-padding slide-up-block thomas-mail">
            {prompt && <Prompt setPrompt={setPrompt} text={i18n.t("waldogame:Screen23.prompt")}/>}
            <img src={i18n.t("waldogame:Screen23.bg")} alt="" className="bg-img"/>
            {link && <NextWiget changeScreen={changeScreen} screen="Screen_24" />}
            {!link && <div className="next-wiget no-z-index" onClick={() => {setPrompt(true); setLink(true)}}>
                <img src="/images/find-waldo/next-arr.svg" alt=""/>
            </div>}
        </div>
    )
}