import React, {useState} from "react"
import {NextWiget} from "../NextWiget";

export const Screen_16 = ({changeScreen}) => {
    return(
        <div className="waldo-game__default-block no-padding slide-up-block" style={{overflow: "auto"}}>
            <img src="/images/find-waldo/bg_5.jpg" alt="" className="bg-img"/>
            <NextWiget changeScreen={changeScreen} screen="Screen_17" />
        </div>
    )
}