import React, {useState} from "react"
import {NextWiget} from "../NextWiget";

export const Screen_4 = ({changeScreen, i18n}) => {
    return(
        <div className="waldo-game__default-block google-map-format slide-up-block">
            <div className="google-map-format__head">
                <div className="title">Queen Mary's Peak</div>
                <div className="tab">{i18n.t("waldogame:Screen4.text1")}</div>
            </div>
            <div className="google-map-format__body">
                <div className="body__user-block">
                    <img src="/images/find-waldo/user-img.jpg" alt=""/>
                    <div>{i18n.t("waldogame:Screen4.text2")}</div>
                </div>
                <div className="body__comment">
                    <img src="/images/find-waldo/rating.svg" className="rating" />
                    <p>{i18n.t("waldogame:Screen4.text3")}</p>
                </div>
                <img src="/images/find-waldo/img_1.jpg" alt="" className="body-img"/>
            </div>
            <NextWiget changeScreen={changeScreen} screen="Screen_5" />
        </div>
    )
}