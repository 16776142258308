export default [
  {
    letters: "",
    value: 1,
  },
  {
    letters: "abc",
    value: 2,
  },
  {
    letters: "def",
    value: 3,
  },
  {
    letters: "ghi",
    value: 4,
  },
  {
    letters: "jkl",
    value: 5,
  },
  {
    letters: "mno",
    value: 6,
  },
  {
    letters: "pqrs",
    value: 7,
  },
  {
    letters: "tuv",
    value: 8,
  },
  {
    letters: "wxyz",
    value: 9,
  },
  {
    letters: "",
    value: 0,
  },
];
