import React, { useState } from "react";

export const Screen_1 = ({ changeScreen, i18n }) => {
  const [pollAnswer, setPollAnswer] = useState(false);

  const pollAnswerHandler = () => {
    setPollAnswer(true);
    setTimeout(() => {
      changeScreen("Screen_2");
    }, 1000);
  };

  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block gradient-type-1">
        <div className="title">{i18n.t("waldogame:Screen1.title")}</div>
        <p>{i18n.t("waldogame:Screen1.text1")}</p>
        <div className="poll-block">
          {pollAnswer ? (
            <img
              src="/images/find-waldo/poll-answer.png"
              alt=""
              className="pool-img"
            />
          ) : (
            <img
              src="/images/find-waldo/poll.png"
              alt=""
              className="pool-img"
              onClick={pollAnswerHandler}
            />
          )}
          <img
            src="/images/find-waldo/postcard_1.png"
            alt=""
            className="postcard_1"
          />
        </div>
      </div>
    </div>
  );
};
