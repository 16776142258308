import React from "react";
import classNames from "classnames";

import "./points-header.scss";

import ProgressLabel from "./ProgressLabel";
import PointsLabel from "./PointsLabel";

function PointsHeader({
  className = "",
  currentProgress,
  totalProgress,
  points,
}) {
  return (
    <div className={classNames(className, "points-header")}>
      <div className="points-header__wrapper points-header__wrapper--desktop" />

      <div className="points-header__wrapper points-header__wrapper--center">
        <ProgressLabel
          className="points-header__progress"
          current={currentProgress}
          total={totalProgress}
        />
      </div>

      <div className="points-header__wrapper points-header__wrapper--right">
        <PointsLabel className="points-header__points" points={points} />
      </div>
    </div>
  );
}

export default PointsHeader;
