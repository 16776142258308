import { useRef, useState } from "react";
import Swiper from "swiper";

import settings from "../config/settings";

function useConveyorSlider() {
  let swiperRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(null);
  const [animating, setAnimating] = useState(false);

  const moveSlider = (prevType = "news") => {
    if (!swiperRef.current) return;

    if (prevType === "level") {
      swiperRef.current.params.speed = settings.LEVEL_ANIMATION_TIME;
    } else {
      swiperRef.current.params.speed = settings.CONVEYOR_SPEED;
    }

    swiperRef.current.slidePrev();
  };

  const initSlider = (initialIndex, startConveyor, pauseConveyor) => {
    setActiveIndex(initialIndex);

    swiperRef.current = new Swiper(".swiper", {
      speed: settings.CONVEYOR_SPEED,
      spaceBetween: settings.GAP,
      centeredSlides: true,
      allowTouchMove: false,
      slidesPerView: "auto",
      observer: true,
      resizeObserver: true,
      initialSlide: initialIndex,
      on: {
        slideChange: (swiper) => {
          setActiveIndex(swiper.activeIndex);
        },
        transitionStart: () => {
          setAnimating(true);

          startConveyor();
        },
        transitionEnd: (swiper) => {
          setAnimating(false);

          if (swiper.activeIndex === initialIndex) {
            setTimeout(() => pauseConveyor(), settings.LEVEL_ANIMATION_TIME);
          } else {
            pauseConveyor();
          }
        },
      },
    });

    initiallyMoveSlider();
  };

  const checkIntersect = (labelCords) => {
    if (!swiperRef.current) return;

    const cardElement = swiperRef.current.slides[activeIndex];

    if (!cardElement) return;

    const cardCords = cardElement.getBoundingClientRect();

    return !(
      cardCords.right < labelCords.left ||
      cardCords.left > labelCords.right ||
      cardCords.bottom < labelCords.top ||
      cardCords.top > labelCords.bottom
    );
  };

  const initiallyMoveSlider = () => {
    swiperRef.current.el.style.translate = "none";
  };

  const resetSliderPosition = () => {
    swiperRef.current.el.style.transition = "unset";
    swiperRef.current.el.style.translate = "-100%";

    setTimeout(() => {
      swiperRef.current.el.style.transition = "translate 8s linear";
    });
  };

  return {
    activeIndex,

    initSlider,

    moveSlider,

    checkIntersect,

    initiallyMoveSlider,
    resetSliderPosition,

    animating,
  };
}

export default useConveyorSlider;
