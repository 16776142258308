import React, { useContext, useRef } from "react";
import classNames from "classnames";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";

import "./license-bundle.scss";

import License from "./License";
import OutlinedButton from "../../../../ui/OutlinedButton";
import { LangContext } from "../../../../../App.js";

function LicenseBundle({ className = "" }) {
  const licenseRef = useRef(null);
  const i18n = useContext(LangContext);

  const htmlToImageConvert = () => {
    toPng(licenseRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "license.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const share = () => {
    (async () => {
      if (!("share" in navigator)) return;

      const canvas = await html2canvas(licenseRef.current);
      canvas.toBlob(async (blob) => {
        const files = [new File([blob], "license.png", { type: blob.type })];
        const shareData = {
          title: i18n.t("navigatemedia:license"),
          url: window.location.href,
          files,
        };

        if (navigator.canShare(shareData)) {
          try {
            await navigator.share(shareData);
          } catch (err) {
            if (err.name !== "AbortError") {
              console.error(err.name, err.message);
            }
          }
        } else {
          console.warn("Sharing not supported", shareData);
        }
      });
    })();
  };
  return (
    <div className={classNames(className, "license-bundle")}>
      <License ref={licenseRef} />

      <OutlinedButton
        className="license-bundle__button license-bundle__button--share"
        onClick={share}
      >
        Share
      </OutlinedButton>
      <OutlinedButton
        className="license-bundle__button license-bundle__button--download"
        onClick={htmlToImageConvert}
      >
        {i18n.t("navigatemedia:download")}
      </OutlinedButton>
    </div>
  );
}

export default LicenseBundle;
