import { useEffect, useRef, useState } from "react";

import settings from "../config/settings";
import levels from "../levels";

import callWithDelay from "../../../helpers/callWithDelay";

import useConveyorSlider from "./useConveyorSlider";
import labels from "../config/labels";
import { useCookies } from "react-cookie";

function useConveyorCore() {
  const {
    activeIndex,
    moveSlider,
    initSlider,
    resetSliderPosition,
    animating,
  } = useConveyorSlider();

  const [timer, setTimer] = useState(settings.ANSWER_TIME / 1000);
  const [showTimer, setShowTimer] = useState(false);

  const [conveyorPaused, setConveyorPaused] = useState(false);

  const [levelLabels, setLevelLabels] = useState([]);
  const [slides, setSlides] = useState([]);
  const [timeouts, setTimeouts] = useState([]);
  const [attempts, setAttempts] = useState(settings.ATTEMPTS);

  const [cookies, setCookie, removeCookie] = useCookies(["conveyorLevel"]);
  const [answers, setAnswers] = useState({});
  const [explanationIndex, setExplanationIndex] = useState(undefined);

  const [showLabels, setShowLabels] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const attemptsRef = useRef(null);
  const timerRef = useRef(null);
  const explanationIndexRef = useRef(null);
  const explanationTimerIdRef = useRef(null);
  const answerTimerIdRef = useRef(null);

  timerRef.current = timer;
  attemptsRef.current = attempts;
  explanationIndexRef.current = explanationIndex;

  document.fonts.ready.then(function () {
    setLoading(false);
  });

  const addTimeout = (id) => {
    setTimeouts((value) => [...value, id]);
  };

  const clearTimeouts = () => {
    timeouts.forEach((id) => clearTimeout(id));
    clearTimeout(explanationTimerIdRef.current);
    clearTimeout(answerTimerIdRef.current);
  };

  const pauseConveyor = () => {
    setConveyorPaused(true);
  };

  const startConveyor = () => {
    setConveyorPaused(false);
  };

  const updateAttempts = (newValue) => {
    setAttempts((value) => value + newValue);
  };

  const addAnswer = (isCorrect, index) => {
    setAnswers((value) => ({
      ...value,
      [index]: isCorrect,
    }));

    if (!isCorrect) {
      updateAttempts(-1);
    }
  };

  const handleAnswer = (id, timeEnd = false) => {
    if (animating && !timeEnd) return;

    clearTimeout(answerTimerIdRef.current);

    if (answers[activeIndex] === undefined) {
      setTimer(0);

      const isCorrect = slides[activeIndex].labelId === id;

      addAnswer(isCorrect, activeIndex);

      // Show explanation
      setExplanationIndex(activeIndex);

      // Next slide
      setTimeout(() => {
        if (attemptsRef.current > 0) {
          explanationTimerIdRef.current = setTimeout(() => {
            if (explanationIndexRef.current) {
              moveSlider();
              setExplanationIndex(undefined);
              clearTimeout(explanationTimerIdRef.current);
            }
          }, settings.EXPLANATION_TIME);
        } else {
          endGame();
        }
      });
    }
  };

  const onExplanationClick = () => {
    if (animating) return;

    clearTimeout(explanationTimerIdRef.current);

    if (activeIndex === explanationIndex) {
      setExplanationIndex(undefined);

      moveSlider();
    } else {
      setExplanationIndex(undefined);
    }
  };

  const clearSlides = () => {
    setSlides([]);
  };

  const startGame = () => {
    setSlides(() => {
      let value;
      const initialLevelValue = cookies.conveyorLevel || settings.INITIAL_LEVEL;

      if (initialLevelValue === 1) {
        value = levels.reverse();
      } else {
        const levelSlidesAmount = {
          1: 10,
          2: 15,
          3: 20,
        };

        const levelIndex = levels.findIndex(
          (item) => item.type === "level" && item.value === initialLevelValue,
        );

        value = levels
          .splice(levelIndex, levelSlidesAmount[initialLevelValue])
          .reverse();
      }

      initSlider(value.length - 1, startConveyor, pauseConveyor);

      return value;
    });
  };

  const restartGame = () => {
    setShowPopup(false);
    clearTimeouts();
    setShowTimer(false);
    setTimer(settings.ANSWER_TIME / 1000);
    setAnswers({});
    setAttempts(settings.ATTEMPTS);

    callWithDelay(startGame, "medium");
  };

  const endGame = () => {
    setShowLabels(false);
    setExplanationIndex(undefined);
    clearTimeouts();
    clearSlides();
    resetSliderPosition();

    setShowPopup(true);
  };

  const setCardTimer = () => {
    const remainingTime = timerRef.current - 1;

    if (remainingTime > 0) {
      setShowTimer(true);

      setTimer(remainingTime);
      const timerId = setTimeout(() => setCardTimer(), 1000);

      addTimeout(timerId);
    } else {
      setShowTimer(false);

      setTimer(settings.ANSWER_TIME / 1000);
    }
  };

  // Initialization
  useEffect(() => {
    if (!loading) {
      startGame();
    }
  }, [loading]);

  // Handle slides changing
  useEffect(() => {
    const slide = slides[activeIndex];
    if (!slide) return;

    // Show labels on news
    if (slide.type === "news") {
      setShowLabels(true);

      callWithDelay(() => setCardTimer());
      const currentIndex = activeIndex;

      answerTimerIdRef.current = setTimeout(() => {
        let currentAnswers;

        setAnswers((value) => {
          currentAnswers = value;
          return value;
        });

        if (currentAnswers[currentIndex] === undefined) {
          handleAnswer({}, true);
        }
      }, settings.ANSWER_TIME);
    } else {
      setShowLabels(false);
    }

    // Level slides auto slide
    if (slide.type === "level") {
      setLevelLabels(labels[slide.value]);
      clearTimeouts();

      const timerId = setTimeout(
        () =>
          callWithDelay(() => {
            moveSlider("level");
          }),
        settings.LEVEL_ANIMATION_TIME,
      );

      setCookie("conveyorLevel", slide.value, { maxAge: 600 });
      addTimeout(timerId);
    }
  }, [activeIndex]);

  return {
    activeIndex,

    answers,

    slides,
    levelLabels,

    attempts,

    handleAnswer,

    showLabels,
    showPopup,

    explanationIndex,

    restartGame,

    conveyorPaused,
    onExplanationClick,

    timer,
    showTimer,
  };
}

export default useConveyorCore;
