const signs = {
  EMOTIONS: {
    id: "emotions",
    name: "navigatemedia:signs.emotions",
  },
  SOURCE: {
    id: "source",
    name: "navigatemedia:signs.source",
  },
  EXPERT: {
    id: "expert",
    name: "navigatemedia:signs.expert",
  },
  SUS: {
    id: "sus",
    name: "navigatemedia:signs.sus",
  },
  FACEBOOK_AD: {
    id: "facebook-ad",
    name: "navigatemedia:signs.facebook-ad",
  },
  COGN: {
    id: "cogn",
    name: "navigatemedia:signs.cogn",
  },
  PREJUDICE: {
    id: "prejudice",
    name: "navigatemedia:signs.prejudice",
  },
  BOT: {
    id: "bot",
    name: "navigatemedia:signs.bot",
  },
  TROLL: {
    id: "troll",
    name: "navigatemedia:signs.troll",
  },
  CONSPIRACY: {
    id: "conspiracy",
    name: "navigatemedia:signs.conspiracy",
  },
  WHATABOUTISM: {
    id: "whataboutism",
    name: "navigatemedia:signs.whataboutism",
  },
  COMMENTS: {
    id: "comments",
    name: "navigatemedia:signs.comments",
  },
  MESSENGER: {
    id: "messenger",
    name: "navigatemedia:signs.messenger",
  },
  PROPAGANDA: {
    id: "propaganda",
    name: "navigatemedia:signs.propaganda",
  },
  FACT: {
    id: "fact",
    name: "navigatemedia:signs.fact",
  },
  FALSE_BALANCE: {
    id: "false-balance",
    name: "navigatemedia:signs.false-balance",
  },
  HYPE: {
    id: "hype",
    name: "navigatemedia:signs.hype",
  },
  MISTAKES: {
    id: "mistakes",
    name: "navigatemedia:signs.mistakes",
  },
  JEANS: {
    id: "jeans",
    name: "navigatemedia:signs.jeans",
  },
  ROUTE: {
    id: "route",
    name: "navigatemedia:signs.route",
  },
  FUN: {
    id: "fun",
    name: "navigatemedia:signs.fun",
  },
  DOCUMENTARY: {
    id: "documentary",
    name: "navigatemedia:signs.documentary",
  },
  FEAR: {
    id: "fear",
    name: "navigatemedia:signs.fear",
  },
  AUDIENCE: {
    id: "audience",
    name: "navigatemedia:signs.audience",
  },
  MEDIA_OWNER: {
    id: "media-owner",
    name: "navigatemedia:signs.media-owner",
  },
  ANALYSIS: {
    id: "analysis",
    name: "navigatemedia:signs.analysis",
  },
  HISTORY: {
    id: "history",
    name: "navigatemedia:signs.history",
  },
  MANIPULATION: {
    id: "manipulation",
    name: "navigatemedia:signs.manipulation",
  },
  CRITICAL: {
    id: "critical",
    name: "navigatemedia:signs.critical",
  },
  POLITICS: {
    id: "politics",
    name: "navigatemedia:signs.politics",
  },
  TRAP: {
    id: "trap",
    name: "navigatemedia:signs.trap",
  },
  NONAME: {
    id: "noname",
    name: "navigatemedia:signs.noname",
  },
  PRIVATE: {
    id: "private",
    name: "navigatemedia:signs.private",
  },
  STEREOTYPE: {
    id: "stereotype",
    name: "navigatemedia:signs.stereotype",
  },
  CLICKBAIT: {
    id: "clickbait",
    name: "navigatemedia:signs.clickbait",
  },
  REST: {
    id: "rest",
    name: "navigatemedia:signs.rest",
  },
};

export const signsLevel1 = [
  signs.SUS,
  signs.EXPERT,
  signs.SOURCE,
  signs.FACEBOOK_AD,
  signs.EMOTIONS,
  signs.COGN,
];

export const signsLevel2 = [
  signs.CONSPIRACY,
  signs.BOT,
  signs.WHATABOUTISM,
  signs.PREJUDICE,
  signs.TROLL,
  signs.COMMENTS,
];

export const signsLevel3 = [
  signs.FACT,
  signs.MISTAKES,
  signs.MESSENGER,
  signs.HYPE,
  signs.FALSE_BALANCE,
  signs.PROPAGANDA,
];

export const signsLevel4 = [
  signs.FEAR,
  signs.AUDIENCE,
  signs.ROUTE,
  signs.DOCUMENTARY,
  signs.JEANS,
  signs.FUN,
];

export const signsLevel5 = [
  signs.HISTORY,
  signs.POLITICS,
  signs.MEDIA_OWNER,
  signs.MANIPULATION,
  signs.CRITICAL,
  signs.ANALYSIS,
];

export const signsLevel6 = [
  signs.CLICKBAIT,
  signs.NONAME,
  signs.PRIVATE,
  signs.STEREOTYPE,
  signs.TRAP,
  signs.REST,
];

export const leveledSigns = {
  1: signsLevel1,
  2: signsLevel2,
  3: signsLevel3,
  4: signsLevel4,
  5: signsLevel5,
  6: signsLevel6,
};

export default signs;
