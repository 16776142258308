import React from "react";

export const EndGameScreen = ({ points, i18n }) => {
  return (
    <div className="game-container final-container">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="container-content">
        <div className="cup-block">
          <img src="/images/game1/gold-cup.svg" alt="" />
          <div className="total-score">
            <div>
              <span>{i18n.t("common:Points")}</span>{" "}
              <p className="pixel-font large">{points}</p>
              <p className="pixel-font">/20</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
