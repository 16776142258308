import React, { useContext } from "react";

import "./styles.scss";

import useLocale from "../../hooks/useLocale";
import useProveMeWrong from "./hooks/useProveMeWrong";

import { LangContext } from "../../App";

import Default from "../../layout/Default";
import ChatWindow from "./components/blocks/ChatWindow";
import PointsLabel from "../blocks/PointsLabel";
import ThankYou from "../../common/ThankYou/ThankYou";

function ProveMeWrong({ match }) {
  const i18n = useContext(LangContext);

  const {
    chatItems,
    showResponses,
    responses,
    selectResponse,
    points,
    showPopup,
    popupData,
    isResult,
    containerRef,
  } = useProveMeWrong();

  useLocale(match.params.locale);

  return (
    <Default className="game-prove-me-wrong" title="Prove me wrong">
      <div className="game-prove-me-wrong__container">
        <PointsLabel className="game-prove-me-wrong__points" points={points} />

        {isResult ? (
          <ThankYou className="game-prove-me-wrong__thanks" />
        ) : (
          <ChatWindow
            className="game-prove-me-wrong__chat"
            items={chatItems}
            responses={responses}
            showResponses={showResponses}
            selectResponse={selectResponse}
            showPopup={showPopup}
            i18n={i18n}
            popupData={popupData}
            containerRef={containerRef}
          />
        )}
      </div>
    </Default>
  );
}

export default ProveMeWrong;
