import React from "react";
import classNames from "classnames";

import "./circle-progress.scss";

function CircleProgress({ className = "", progress }) {
  return (
    <svg
      className={classNames(className, "circle-progress")}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-4 -4 40 40"
    >
      <circle
        cx="16"
        cy="16"
        r="15.9155"
        className="circle-progress__bar circle-progress__bar--full"
      />
      <circle
        cx="16"
        cy="16"
        r="15.9155"
        className="circle-progress__bar"
        strokeDashoffset={100 - progress}
      />
    </svg>
  );
}

export default CircleProgress;
