import signs from "./signs";

export default [
  {
    title: "navigatemedia:situations.1.title",
    text: "navigatemedia:situations.1.text",
    answerId: signs.EMOTIONS.id,
  },
  {
    title: "navigatemedia:situations.2.title",
    text: "navigatemedia:situations.2.text",
    answerId: signs.SOURCE.id,
  },
  {
    title: "navigatemedia:situations.3.title",
    text: "navigatemedia:situations.3.text",
    answerId: signs.EXPERT.id,
  },
  {
    title: "navigatemedia:situations.4.title",
    text: "navigatemedia:situations.4.text",
    answerId: signs.SUS.id,
  },
  {
    title: "navigatemedia:situations.5.title",
    text: "navigatemedia:situations.5.text",
    answerId: signs.FACEBOOK_AD.id,
  },
  {
    title: "navigatemedia:situations.6.title",
    text: "navigatemedia:situations.6.text",
    answerId: signs.COGN.id,
  },
];
