import React, { createElement, useRef } from "react";
import classNames from "classnames";

import "./news-label.scss";

function NewsLabel({ className = "", id, name, icon, color, setLabel }) {
  const labelRef = useRef(null);

  return (
    <div
      ref={labelRef}
      className={classNames(className, "news-label")}
      style={{ background: color }}
      onClick={() => setLabel(id)}
    >
      {createElement(icon, { className: "news-label__icon" })}

      <div className="news-label__name">
        <span className="news-label__font font--text">{name}</span>
      </div>
    </div>
  );
}

export default NewsLabel;
