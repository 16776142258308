export default {
  CONVEYOR_SPEED: 3000,
  CONVEYOR_SPEED_SCALE: 0.9,

  GAP: 110,

  ANSWER_TIME: 27000 + 3000, // add CONVEYOR_SPEED cause of animation time
  EXPLANATION_TIME: 15000,
  LEVEL_ANIMATION_TIME: 8000,

  INITIAL_LEVEL: 1,

  ATTEMPTS: 5,
};
