import React from "react";
import classNames from "classnames";

import "./effect-card.scss";

function EffectCard({ className = "", value }) {
  return (
    <div className={classNames(className, "effect-card")}>
      <span className="effect-card__font font--text">{value}</span>
    </div>
  );
}

export default EffectCard;
