import level1 from "./level-1";
import level2 from "./level-2";
import level3 from "./level-3";
import level4 from "./level-4";
import level5 from "./level-5";
import level6 from "./level-6";

export default {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
};
