export default (callback, delay = "medium") => {
  switch (delay) {
    case "short":
      setTimeout(callback, 500);
      break;

    case "medium":
      setTimeout(callback, 1000);
      break;

    case "long":
      setTimeout(callback, 2000);
      break;
  }
};
