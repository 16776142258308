import React from "react";
import classNames from "classnames";

import "./lifes-label.scss";

function LifesLabel({ className = "", lifes }) {
  return (
    <div className={classNames(className, "lifes-label")}>
      <img className="lifes-label__icon" src="/images/heart.svg" alt="star" />

      <div className="lifes-label__score">
        <span className="lifes-label__font">{lifes}</span>
      </div>
    </div>
  );
}

export default LifesLabel;
