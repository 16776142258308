import classNames from "classnames";
import React from "react";

import keyboard from "./config/keyboard";

export const Keyboard = ({ className, btnPress }) => {
  return (
    <div className={classNames(className, "keyboard")}>
      {keyboard.map((key) => (
        <div
          className="keyboard__item"
          key={key.value}
          onMouseDown={() => btnPress(key.value)}
        >
          <div className="keyboard__number">
            <span className="keyboard__font keyboard__font--number font--text">
              {key.value}
            </span>
          </div>

          <div className="keyboard__letters">
            <span className="keyboard__font keyboard__font--letters font--text">
              {key.letters}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
