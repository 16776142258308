import React from "react";
import classNames from "classnames";

import "./points-label.scss";

function PointsLabel({ className = "", points }) {
  return (
    <div className={classNames(className, "points-label")}>
      <img className="points-label__icon" src="/images/star.svg" alt="star" />

      <div className="points-label__score">
        <span className="points-label__font">{points}</span>
      </div>
    </div>
  );
}

export default PointsLabel;
