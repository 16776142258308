import React, { createElement, useContext, useRef } from "react";
import classNames from "classnames";

import "./news-card.scss";

import { ReactComponent as Arrow } from "../../../../assets/svg/chevrone.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/svg/conveyor/check.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/svg/conveyor/cross.svg";
import { LangContext } from "../../../../App";
import { labelsDictionary } from "../../config/labels";

function NewsCard({
  className = "",
  authorImage,
  authorName,
  authorNick,
  text,
  explanation,
  imageSrc,
  labelId,
  isActive,
  isHidden,
  showExplanation = false,
  isAnswered = false,
  isCorrect = false,
  onExplanationClick,
}) {
  const i18n = useContext(LangContext);

  const answerIcon = isCorrect ? CheckIcon : CrossIcon;

  const textRef = useRef(null);
  const scrollValue = 15;

  const scrollUp = () => {
    if (!textRef.current) return;
    textRef.current.scrollTop -= scrollValue;
  };

  const scrollDown = () => {
    if (!textRef.current) return;
    textRef.current.scrollTop += scrollValue;
  };

  return (
    <div
      className={classNames(className, "news-card", {
        "news-card--active": isActive && !showExplanation,
        "news-card--hidden": isHidden,
      })}
    >
      {isAnswered && (
        <div className="news-card__icons">
          {labelId &&
            createElement(labelsDictionary[labelId].icon, {
              className: "news-card__icon news-card__icon--label",
            })}

          {createElement(answerIcon, {
            className: "news-card__icon news-card__icon--answer",
          })}
        </div>
      )}

      <div
        className={classNames("news-card__explanation-wrapper", {
          "news-card__explanation-wrapper--show": isAnswered,
        })}
        onClick={onExplanationClick}
      >
        <div
          className={classNames("news-card__explanation", {
            "news-card__explanation--show": isAnswered && showExplanation,
          })}
        >
          <span className="news-card__font news-card__font--explanation-title font--text">
            {i18n.t("common:Explanation")}
          </span>
          <br /> <br />
          <span className="news-card__font news-card__font--explanation font--text">
            {explanation}
          </span>
          <div className="news-card__explanation-click">
            <span className="news-card__font news-card__font--explanation font--text">
              {i18n.t("conveyor:click")}
            </span>
          </div>
        </div>
      </div>

      <div className="news-card__head">
        <img className="news-card__avatar" src={authorImage} alt="avatar" />

        <div className="news-card__author">
          <div className="news-card__name">
            <span className="news-card__font news-card__font--name font--text">
              {authorName}
            </span>
          </div>

          <div className="news-card__nick">
            <span className="news-card__font news-card__font--nick font--text">
              {authorNick}
            </span>
          </div>
        </div>
      </div>

      <div ref={textRef} className="news-card__text">
        <span className="news-card__font news-card__font--nick font--text">
          {text}
        </span>
      </div>

      <div className="news-card__buttons">
        <button className="news-card__button" onClick={scrollUp}>
          <Arrow className="news-card__arrow news-card__arrow--up" />
        </button>

        <button className="news-card__button" onClick={scrollDown}>
          <Arrow className="news-card__arrow news-card__arrow--down" />
        </button>
      </div>

      {imageSrc && (
        <img className="news-card__image" src={imageSrc} alt="image" />
      )}
    </div>
  );
}

export default NewsCard;
