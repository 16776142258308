import React, { useState } from "react";

import Default from "../../../layout/Default";
import OutlinedButton from "../../ui/OutlinedButton";
import ThankYou from "../../../common/ThankYou/ThankYou";

export const EndGameScreen = ({ points, changeScreen, i18n }) => {
  const [isThanks, setIsThanks] = useState(false);

  return (
    <Default className="game-tinder-result" title="News Tinder">
      {isThanks ? (
        <ThankYou className="game-tinder-result__thanks" />
      ) : (
        <>
          <div className="game-tinder-result__container">
            <div className="game-tinder-result__content">
              <div className="game-tinder-result__title">
                <span className="game-tinder-result__font game-tinder-result__font--title font--title">
                  {points}/20
                </span>
              </div>

              <div className="game-tinder-result__subtitle">
                <span className="game-tinder-result__font game-tinder-result__font--text font--text">
                  {i18n.t("newstinder:EndGame.title")}
                </span>
              </div>

              <div className="game-tinder-result__text">
                <span className="game-tinder-result__font game-tinder-result__font--text font--text">
                  {i18n.t("newstinder:EndGame.advice")} 1
                </span>
                <span className="game-tinder-result__font game-tinder-result__font--text font--text">
                  {i18n.t("newstinder:EndGame.text1")}
                </span>
                <br />
                <span className="game-tinder-result__font game-tinder-result__font--text font--text">
                  {i18n.t("newstinder:EndGame.advice")} 2
                </span>
                <span className="game-tinder-result__font game-tinder-result__font--text font--text">
                  {i18n.t("newstinder:EndGame.text2")}
                </span>
              </div>
            </div>

            <OutlinedButton
              className="game-tinder-result__button"
              color="green"
              onClick={() => setIsThanks(true)}
            >
              {i18n.t("common:Buttons.next")}
            </OutlinedButton>
          </div>
        </>
      )}
    </Default>
  );
};
