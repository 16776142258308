import React, { useState } from "react";
import { NextWiget } from "../NextWiget";

export const Screen_17 = ({ changeScreen, i18n }) => {
  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block gradient-type-2">
        <img
          src="/images/find-waldo/compass.svg"
          alt=""
          style={{ marginBottom: "10px" }}
        />
        <div className="simple-text">
          {i18n.t("waldogame:Screen17.text1")}
          <br />
          {i18n.t("waldogame:Screen17.text2")},<br />
          {i18n.t("waldogame:Screen17.text3")}
          <br />
          {i18n.t("waldogame:Screen17.text4")}
          <br />
          Google
          <br />
          Street View
        </div>
        <NextWiget changeScreen={changeScreen} screen="Screen_18" />
      </div>
    </div>
  );
};
