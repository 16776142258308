import React, { forwardRef, useContext, useState } from "react";
import classNames from "classnames";

import "./license.scss";

import { LangContext } from "../../../../../App.js";

import ImageInput from "./imageInput/ImageInput";
import TextInput from "./textInput/TextInput";

const License = forwardRef(({ className }, ref) => {
  const i18n = useContext(LangContext);

  const [photo, setPhoto] = useState(undefined);
  const [firstName, setFirstName] = useState(undefined);
  const [secondName, setSecondName] = useState(undefined);
  const [birthDate, setBirthDate] = useState(undefined);

  return (
    <div ref={ref} className={classNames(className, "license")}>
      <img className="license__mark" src="/images/mark.png" alt="mark" />

      <div className="license__head">
        <span className="license__font license__font--title font--title">
          {i18n.t("navigatemedia:license")}
        </span>
      </div>

      <div className="license__body">
        <ImageInput
          className="license__input license__input--image"
          value={photo}
          onChange={setPhoto}
        />

        <div className="license__wrapper">
          <TextInput
            className="license__input license__input--name"
            placeholder={i18n.t("navigatemedia:name")}
            index="1"
            value={firstName}
            onInput={setFirstName}
          />
          <TextInput
            className="license__input license__input--surname"
            placeholder={i18n.t("navigatemedia:surname")}
            index="2"
            value={secondName}
            onInput={setSecondName}
          />
          <TextInput
            className="license__input license__input--date"
            placeholder={i18n.t("navigatemedia:birth")}
            index="3"
            value={birthDate}
            onInput={setBirthDate}
          />

          <div className="license__texts">
            <div className="license__text">
              <span className="license__font license__font--text font--text">
                M1900y
              </span>
            </div>

            <div className="license__text">
              <span className="license__font license__font--text font--text">
                {i18n.t("navigatemedia:class")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default License;
