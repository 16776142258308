import signs from "./signs";

export default [
  {
    title: "navigatemedia:situations.31.title",
    text: "navigatemedia:situations.31.text",
    answerId: signs.TRAP.id,
  },
  {
    title: "navigatemedia:situations.32.title",
    text: "navigatemedia:situations.32.text",
    answerId: signs.NONAME.id,
  },
  {
    title: "navigatemedia:situations.33.title",
    text: "navigatemedia:situations.33.text",
    answerId: signs.PRIVATE.id,
  },
  {
    title: "navigatemedia:situations.34.title",
    text: "navigatemedia:situations.34.text",
    answerId: signs.STEREOTYPE.id,
  },
  {
    title: "navigatemedia:situations.35.title",
    text: "navigatemedia:situations.35.text",
    answerId: signs.CLICKBAIT.id,
  },
  {
    title: "navigatemedia:situations.36.title",
    text: "navigatemedia:situations.36.text",
    answerId: signs.REST.id,
  },
];
