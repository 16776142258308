import { labelsDictionary } from "../config/labels";

export default [
  {
    id: 1,
    type: "news",
    authorName: "conveyor:levels.3.news.1.authorName",
    authorNick: "conveyor:levels.3.news.1.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q1.svg",
    text: "conveyor:levels.3.news.1.text",
    explanation: "conveyor:levels.3.news.1.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q1.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
  {
    id: 2,
    type: "news",
    authorName: "conveyor:levels.3.news.2.authorName",
    authorNick: "conveyor:levels.3.news.2.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q2.jpeg",
    text: "conveyor:levels.3.news.2.text",
    explanation: "conveyor:levels.3.news.2.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q2.jpeg",
    labelIcon: labelsDictionary[4].icon,
    labelId: labelsDictionary[4].id,
  },
  {
    id: 3,
    type: "news",
    authorName: "conveyor:levels.3.news.3.authorName",
    authorNick: "conveyor:levels.3.news.3.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q3.jpeg",
    text: "conveyor:levels.3.news.3.text",
    explanation: "conveyor:levels.3.news.3.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q3.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 4,
    type: "news",
    authorName: "conveyor:levels.3.news.4.authorName",
    authorNick: "conveyor:levels.3.news.4.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q4.jpeg",
    text: "conveyor:levels.3.news.4.text",
    explanation: "conveyor:levels.3.news.4.explanation",
    labelIcon: labelsDictionary[6].icon,
    labelId: labelsDictionary[6].id,
  },
  {
    id: 5,
    type: "news",
    authorName: "conveyor:levels.3.news.5.authorName",
    authorNick: "conveyor:levels.3.news.5.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q5.webp",
    text: "conveyor:levels.3.news.5.text",
    explanation: "conveyor:levels.3.news.5.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q5.jpeg",
    labelIcon: labelsDictionary[7].icon,
    labelId: labelsDictionary[7].id,
  },
  {
    id: 6,
    type: "news",
    authorName: "conveyor:levels.3.news.6.authorName",
    authorNick: "conveyor:levels.3.news.6.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q6.png",
    text: "conveyor:levels.3.news.6.text",
    explanation: "conveyor:levels.3.news.6.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q670.jpeg",
    labelIcon: labelsDictionary[7].icon,
    labelId: labelsDictionary[7].id,
  },
  {
    id: 7,
    type: "news",
    authorName: "conveyor:levels.3.news.7.authorName",
    authorNick: "conveyor:levels.3.news.7.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q7.png",
    text: "conveyor:levels.3.news.7.text",
    explanation: "conveyor:levels.3.news.7.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q7.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 8,
    type: "news",
    authorName: "conveyor:levels.3.news.8.authorName",
    authorNick: "conveyor:levels.3.news.8.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q8.png",
    text: "conveyor:levels.3.news.8.text",
    explanation: "conveyor:levels.3.news.8.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q8.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 9,
    type: "news",
    authorName: "conveyor:levels.3.news.9.authorName",
    authorNick: "conveyor:levels.3.news.9.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q9.jpeg",
    text: "conveyor:levels.3.news.9.text",
    explanation: "conveyor:levels.3.news.9.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q9.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 10,
    type: "news",
    authorName: "conveyor:levels.3.news.10.authorName",
    authorNick: "conveyor:levels.3.news.10.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q10.jpeg",
    text: "conveyor:levels.3.news.10.text",
    explanation: "conveyor:levels.3.news.10.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q10.jpeg",
    labelIcon: labelsDictionary[4].icon,
    labelId: labelsDictionary[4].id,
  },
  {
    id: 11,
    type: "news",
    authorName: "conveyor:levels.3.news.11.authorName",
    authorNick: "conveyor:levels.3.news.11.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q11.png",
    text: "conveyor:levels.3.news.11.text",
    explanation: "conveyor:levels.3.news.11.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q11.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
  {
    id: 12,
    type: "news",
    authorName: "conveyor:levels.3.news.12.authorName",
    authorNick: "conveyor:levels.3.news.12.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q12.png",
    text: "conveyor:levels.3.news.12.text",
    explanation: "conveyor:levels.3.news.12.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q12.jpeg",
    labelIcon: labelsDictionary[7].icon,
    labelId: labelsDictionary[7].id,
  },
  {
    id: 13,
    type: "news",
    authorName: "conveyor:levels.3.news.13.authorName",
    authorNick: "conveyor:levels.3.news.13.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q13.jpeg",
    text: "conveyor:levels.3.news.13.text",
    explanation: "conveyor:levels.3.news.13.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q13.jpeg",
    labelIcon: labelsDictionary[7].icon,
    labelId: labelsDictionary[7].id,
  },
  {
    id: 14,
    type: "news",
    authorName: "conveyor:levels.3.news.14.authorName",
    authorNick: "conveyor:levels.3.news.14.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q14.jpeg",
    text: "conveyor:levels.3.news.14.text",
    explanation: "conveyor:levels.3.news.14.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q14.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
  {
    id: 15,
    type: "news",
    authorName: "conveyor:levels.3.news.15.authorName",
    authorNick: "conveyor:levels.3.news.15.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q15.png",
    text: "conveyor:levels.3.news.15.text",
    explanation: "conveyor:levels.3.news.15.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q15.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
  {
    id: 16,
    type: "news",
    authorName: "conveyor:levels.3.news.16.authorName",
    authorNick: "conveyor:levels.3.news.16.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q16.png",
    text: "conveyor:levels.3.news.16.text",
    explanation: "conveyor:levels.3.news.16.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q16.jpeg",
    labelIcon: labelsDictionary[7].icon,
    labelId: labelsDictionary[7].id,
  },
  {
    id: 17,
    type: "news",
    authorName: "conveyor:levels.3.news.17.authorName",
    authorNick: "conveyor:levels.3.news.17.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q17.jpeg",
    text: "conveyor:levels.3.news.17.text",
    explanation: "conveyor:levels.3.news.17.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q17.jpeg",
    labelIcon: labelsDictionary[4].icon,
    labelId: labelsDictionary[4].id,
  },
  {
    id: 18,
    type: "news",
    authorName: "conveyor:levels.3.news.18.authorName",
    authorNick: "conveyor:levels.3.news.18.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q18.png",
    text: "conveyor:levels.3.news.18.text",
    explanation: "conveyor:levels.3.news.18.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q18.jpeg",
    labelIcon: labelsDictionary[4].icon,
    labelId: labelsDictionary[4].id,
  },
  {
    id: 19,
    type: "news",
    authorName: "conveyor:levels.3.news.19.authorName",
    authorNick: "conveyor:levels.3.news.19.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q19.png",
    text: "conveyor:levels.3.news.19.text",
    explanation: "conveyor:levels.3.news.19.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q19.jpeg",
    labelIcon: labelsDictionary[6].icon,
    labelId: labelsDictionary[6].id,
  },
  {
    id: 20,
    type: "news",
    authorName: "conveyor:levels.3.news.20.authorName",
    authorNick: "conveyor:levels.3.news.20.authorNick",
    authorImage: "/images/conveyor/author/level-3/L3Q20.png",
    text: "conveyor:levels.3.news.20.text",
    explanation: "conveyor:levels.3.news.20.explanation",
    imageSrc: "/images/conveyor/news/level-3/Q20.jpeg",
    labelIcon: labelsDictionary[6].icon,
    labelId: labelsDictionary[6].id,
  },
];
