import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const EndScreen = ({ changeScreen }) => {
  const { i18n } = useTranslation();

  return (
    <div className="game-container take-quiz-game end-screen">
      <div className="back-arrow">
        <Link to={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </Link>
      </div>
      <div className="container-content">
        <div className="container-content-wrapper full-height-wrapper">
          <div className="animate-center-box">
            <div className="container-title small-screen">
              <div className="text-frame frame-1">
                Тож якою б<br />
                піснею ви б<br />
                не були,
              </div>
              <div className="text-frame frame-2 green-text">
                будьте
                <br />
                уважними!
              </div>
              <img src="/images/take-quiz/eye.gif" alt="" className="eye-gif" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
