import React from "react";

import OutlinedButton from "../../ui/OutlinedButton";

export const StartGame = ({ changeScreen, i18n }) => {
  return (
    <div className="game-passwords-phone">
      <div className="game-passwords-phone__title">
        <span className="game-passwords-phone__font game-passwords-phone__font--title font--title">
          {i18n.t("passgame:InputPass")}
        </span>
      </div>

      <input
        type="text"
        readOnly="readOnly"
        autoFocus="autoFocus"
        value="12345"
        className="game-passwords-phone__input"
      />

      <div className="game-passwords-phone__text">
        <span className="game-passwords-phone__font game-passwords-phone__font--text font--text">
          {i18n.t("passgame:description.1")}
          <br />
          <br />
          {i18n.t("passgame:description.2")}
          <br />
          <br />
          {i18n.t("passgame:description.3")}
        </span>
      </div>

      <OutlinedButton
        className="game-passwords-phone__button"
        color="green"
        size="medium"
        onClick={() => changeScreen("Pass_1")}
      >
        {i18n.t("passgame:Buttons.start")}
      </OutlinedButton>
    </div>
  );
};
