import React, { useState } from "react";
import { NextWiget } from "../NextWiget";
import { Screen_23 } from "./Screen_23";

export const Screen_22 = ({ changeScreen, i18n }) => {
  const [animation, setAnimation] = useState(false);

  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block default-block-wrapper">
        <div
          className={
            animation
              ? "waldo-game__default-block gradient-type-3 hacking-mail-screen animate"
              : "waldo-game__default-block gradient-type-3 hacking-mail-screen"
          }
        >
          <img src="/images/find-waldo/post-icon.svg" alt="" />
          <div className="simple-text">
            {i18n.t("waldogame:Screen22.text1")}
            <br />
            {i18n.t("waldogame:Screen22.text2")}
            <br />
            {i18n.t("waldogame:Screen22.text3")}
          </div>
          <img src="/images/find-waldo/party-popper.svg" alt="" />
          <div className="next-wiget" onClick={() => setAnimation(true)}>
            <img src="/images/find-waldo/next-arr.svg" alt="" />
          </div>
        </div>
        {animation && <Screen_23 changeScreen={changeScreen} i18n={i18n} />}
      </div>
    </div>
  );
};
