import React, { useContext } from "react";
import classNames from "classnames";

import "./answer-popup.scss";

import OutlinedButton from "../ui/OutlinedButton";
import { LangContext } from "../../App";

function AnswerPopup({
  className = "",
  isTrue = false,
  correctAnswered,
  children,
  onClick,
  buttonText,
}) {
  const i18n = useContext(LangContext);

  const iconSrc = correctAnswered ? "/images/true.svg" : "/images/fake.svg";
  const text = isTrue ? "newstinder:True" : "newstinder:Fake";
  const color = isTrue ? "green" : "pink";

  return (
    <div
      className={classNames(
        className,
        "answer-popup",
        `answer-popup--theme--${color}`,
      )}
    >
      <div className="answer-popup__head">
        <img className="answer-popup__icon" src={iconSrc} alt="icon" />

        <div className="answer-popup__title">
          <span className="answer-popup__font font--title">{i18n.t(text)}</span>
        </div>
      </div>

      <div className="answer-popup__content">{children}</div>

      <OutlinedButton
        className="answer-popup__button"
        color={color}
        onClick={onClick}
      >
        {buttonText}
      </OutlinedButton>
    </div>
  );
}

export default AnswerPopup;
