import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import gamesConfig from "./gamesConfig";
import useLocale from "../../hooks/useLocale";

const HomePage = ({ match }) => {
  useEffect(() => {
    document.body.classList.add("body-home-page");
    return () => {
      document.body.classList.remove("body-home-page");
    };
  }, []);

  useLocale(match.params.locale);

  return (
    <div className="home-page">
      <div className="preview-wrapper">
        <div className="main-content" style={{ paddingBottom: "60px" }}>
          <img src="/images/game1/preview-text.svg" alt="" />
          <div className="info-on-main">
            {gamesConfig.map((game, index) => (
              <div
                key={index}
                style={{ borderBottom: game.hideBorder && "none" }}
              >
                <div>[{index + 1}]</div>
                <Link to={`${match.params.locale}${game.link}`}>
                  {game.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
