import React from "react";
import { Link } from "react-router-dom";

export const QuestionsScreen = ({
  i18n,
  data,
  changeScreen,
  changeStatus,
  changeQuestion,
  changePoints,
  changeGradientTop,
  changeGradientBottom,
  questionNumber,
  gradientTop,
  gradientBottom,
  questions,
  status,
  points,
}) => {
  const answerHandler = (status) => {
    if (status === questions[questionNumber - 1].status) {
      changePoints(points + 1);
    }
    changeStatus(questions[questionNumber - 1].status);
  };

  const onScrollHandler = (event) => {
    const _target = event.target;
    if (_target.scrollTop > 0) {
      changeGradientTop(true);
    } else {
      changeGradientTop(false);
    }

    if (_target.scrollTop === _target.scrollHeight - _target.clientHeight) {
      changeGradientBottom(false);
    } else {
      changeGradientBottom(true);
    }
  };

  return (
    <div className="game-container question-container behind-screen-game">
      <div className="back-arrow nav-panel">
        <Link to={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </Link>
        <div className="question-numbering pixel-font">
          <img src="/images/behind-screen/fire.svg" alt="" />
        </div>
        <div className="points">
          <img src="/images/game1/point.svg" alt="" />
          <span className="pixel-font">
            {questionNumber}/{data.length}
          </span>
        </div>
      </div>
      <div className="container-content">
        <div className="container-content-wrapper" onScroll={onScrollHandler}>
          <div
            className="user-box"
            style={{
              background:
                "url(" +
                data[questionNumber - 1].img +
                ")no-repeat center/cover",
            }}
          >
            <div className="user-info">
              {i18n.t("behindscreen:" + questionNumber + ".name")},{" "}
              {i18n.t("behindscreen:" + questionNumber + ".age")}
            </div>
            <p>{i18n.t("behindscreen:" + questionNumber + ".description")}</p>
          </div>
        </div>
      </div>
      <div className="true-false-btn">
        <div
          className="game-btn false-btn hover-scale"
          onClick={() => answerHandler("fake")}
        ></div>
        <div
          className="game-btn true-btn hover-scale"
          onClick={() => answerHandler("really")}
        ></div>
      </div>
      {status === "fake" && (
        <FakePopup
          i18n={i18n}
          answer={i18n.t("behindscreen:" + questionNumber + ".answer")}
          changeQuestion={changeQuestion}
          questionNumber={questionNumber}
        />
      )}
      {status === "really" && (
        <ReallyPopup
          i18n={i18n}
          answer={i18n.t("behindscreen:" + questionNumber + ".answer")}
          changeQuestion={changeQuestion}
          questionNumber={questionNumber}
        />
      )}
    </div>
  );
};

const FakePopup = ({ answer, changeQuestion, questionNumber, i18n }) => (
  <div className="status-popup fake-status">
    <div className="status-popup-wrapper">
      <div className="status-popup__content">
        <div className="title">{i18n.t("common:Fake")}</div>
        <p>{answer}</p>
        <div className="btn-wrapper">
          <div
            className="game-btn next-btn hover-scale"
            onClick={() => changeQuestion(questionNumber + 1)}
          >
            {i18n.t("common:Buttons.next")}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ReallyPopup = ({ answer, changeQuestion, questionNumber, i18n }) => (
  <div className="status-popup really-status">
    <div className="status-popup-wrapper">
      <div className="status-popup__content">
        <div className="title">{i18n.t("common:True")}</div>
        <p>{answer}</p>
        <div className="btn-wrapper">
          <div
            className="game-btn next-btn hover-scale"
            onClick={() => changeQuestion(questionNumber + 1)}
          >
            {i18n.t("common:Buttons.next")}
          </div>
        </div>
      </div>
    </div>
  </div>
);
