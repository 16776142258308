import React, { useContext } from "react";
import classNames from "classnames";

import "./level-card.scss";

import { LangContext } from "../../../../App.js";

function LevelCard({ className = "", level }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "level-card")}>
      <span className="level-card__font font--title">
        {i18n.t("common:level")} {level}
      </span>
    </div>
  );
}

export default LevelCard;
