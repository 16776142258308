import React, { useContext } from "react";
import classNames from "classnames";

import "./signs.scss";
import { LangContext } from "../../../../App";

function Signs({
  className = "",
  items,
  size = "large",
  correctId = undefined,
  showId = undefined,
  showAll = false,
  answersToShow = [],
  showAnswer = false,
  disableClick = false,
  onClick,
}) {
  const i18n = useContext(LangContext);

  const showName = (id) => {
    if (showAll) return true;

    return (showAnswer && id === showId) || answersToShow.includes(id);
  };

  const iconName = (id) => {
    return id === correctId ? "true" : "fake";
  };

  return (
    <div className={classNames(className, "signs", `signs--size--${size}`)}>
      {items.map((item) => (
        <button
          className="signs__item"
          key={item.id}
          disabled={disableClick}
          onClick={() => onClick(item.id)}
        >
          <div className="signs__image-wrapper">
            <img
              className="signs__image"
              src={`/images/navaigate-in-media-signs/${item.id}.svg`}
              alt={item.id}
            />

            {showAnswer && (item.id === correctId || item.id === showId) && (
              <img
                className="signs__icon"
                src={`/images/${iconName(item.id)}.svg`}
                alt="icon"
              />
            )}
          </div>

          {showName(item.id) && (
            <div className="signs__name">
              <span className="signs__font font--text">
                {i18n.t(item.name)}
              </span>
            </div>
          )}
        </button>
      ))}
    </div>
  );
}

export default Signs;
