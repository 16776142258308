import React, { useContext } from "react";
import classNames from "classnames";

import "./round-modal.scss";

import { LangContext } from "../../../../App";
import OutlinedButton from "../../../ui/OutlinedButton";

function RoundModal({ className = "", level, onClick }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "round-modal")}>
      <div className="round-modal__title">
        <span className="round-modal__font font--title">
          {i18n.t("navigatemedia:round", {
            value: i18n.t(`navigatemedia:${level}`),
          })}
        </span>
      </div>

      <OutlinedButton
        className="round-modal__button"
        color="pink"
        onClick={onClick}
      >
        {i18n.t("common:Buttons.start")}
      </OutlinedButton>
    </div>
  );
}

export default RoundModal;
