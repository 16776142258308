import React, { useEffect } from "react";
import OutlinedButton from "../../ui/OutlinedButton";

export const AccessDenied = ({
  setDenied,
  setAnswer,
  attempt,
  audioError,
  i18n,
}) => {
  useEffect(() => {
    audioError.play();
  }, []);

  const tryAgain = () => {
    if (attempt > 0) {
      setDenied(false);
    } else {
      setDenied(false);
      setAnswer(true);
    }
  };

  return (
    <div className="game-passwords-denied">
      <div className="game-passwords-denied__title">
        <span className="game-passwords-denied__font game-passwords-denied__font--title font--text">
          {i18n.t("passgame:Access")}
          <br />
          {i18n.t("passgame:Denied")}
        </span>
      </div>

      <div className="game-passwords-denied__text">
        <span className="game-passwords-denied__font game-passwords-denied__font--text font--text">
          {i18n.t("passgame:AttemptLeft")}: {attempt}
        </span>
      </div>

      <OutlinedButton
        className="game-passwords-denied__button"
        color="pink"
        size="medium"
        onClick={tryAgain}
      >
        {attempt === 0
          ? i18n.t("passgame:ShowAnswer")
          : i18n.t("passgame:TryAgain")}
      </OutlinedButton>
    </div>
  );
};
