import React, { useContext, useState } from "react";
import { StartGame } from "./screens/StartGame";
import "./style.scss";
import { QuestionsScreen } from "./screens/QuestionsScreen";
import data from "./data.json";
import { EndGameScreen } from "./screens/EndGameScreen";
import { LangContext } from "../../App";
import useLocale from "../../hooks/useLocale";

const BehindScreen = ({ match }) => {
  const [screen, setScreen] = useState("StartGame");
  const [status, setStatus] = useState("");
  const [points, setPoints] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(1);

  const [gradientTop, setGradientTop] = useState(false);
  const [gradientBottom, setGradientBottom] = useState(true);

  const i18n = useContext(LangContext);

  const changeScreen = (screen) => setScreen(screen);
  const changeStatus = (status) => setStatus(status);
  const changePoints = (points) => setPoints(points);
  const changeGradientTop = (activation) => setGradientTop(activation);
  const changeGradientBottom = (activation) => setGradientBottom(activation);
  const changeQuestion = (number) => {
    if (number <= data.length) {
      setQuestionNumber(number);
      setStatus("");
    } else {
      setScreen("EndGameScreen");
    }
  };

  useLocale(match.params.locale);

  return (
    <div
      className={
        screen === "QuestionsScreen"
          ? "game-field white-theme game"
          : "game-field game"
      }
    >
      {screen === "StartGame" && (
        <StartGame changeScreen={changeScreen} i18n={i18n} />
      )}
      {screen === "QuestionsScreen" && (
        <QuestionsScreen
          changeScreen={changeScreen}
          changeStatus={changeStatus}
          changePoints={changePoints}
          changeGradientTop={changeGradientTop}
          changeGradientBottom={changeGradientBottom}
          questionNumber={questionNumber}
          changeQuestion={changeQuestion}
          gradientTop={gradientTop}
          gradientBottom={gradientBottom}
          questions={data}
          points={points}
          data={data}
          i18n={i18n}
          status={status}
        />
      )}
      {screen === "EndGameScreen" && (
        <EndGameScreen points={points} i18n={i18n} />
      )}
    </div>
  );
};

export default BehindScreen;
