import React, { useContext } from "react";
import classNames from "classnames";

import "./level-block.scss";

import { LangContext } from "../../../../App.js";

function LevelBlock({ className = "", value }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "level-block")}>
      <span className="level-block__font font--title">
        {i18n.t("common:level")} {value}
      </span>
    </div>
  );
}

export default LevelBlock;
