import React, {useState} from "react"
import {NextWiget} from "../NextWiget";

export const Screen_7 = ({changeScreen, i18n}) => {
    return(
        <div className="waldo-game__default-block google-map-format slide-up-block">
            <div className="google-map-format__head">
                <div className="title margin-none">Harry Brant</div>
            </div>
            <div className="google-map-format__body">
                <div className="body__title">{i18n.t("waldogame:Screen7.title")}</div>
                <div className="body__user-block">
                    <div>Amsterdam Island</div>
                </div>
                <div className="body__comment">
                    <img src="/images/find-waldo/rating.svg" className="rating" />
                    <p>{i18n.t("waldogame:Screen7.text1")}</p>
                </div>
                <img src="/images/find-waldo/img_2.jpg" alt="" className="body-img"/>
            </div>
            <NextWiget changeScreen={changeScreen} screen="Screen_8" />
        </div>
    )
}
