import { useEffect, useRef, useState } from "react";

import settings from "../config/settings";
import levels from "../levels";
import { leveledSigns } from "../levels/signs";

function useNavigateInMediaSigns() {
  const [attempts, setAttempts] = useState(settings.ATTEMPTS);

  const [level, setLevel] = useState(settings.INITIAL_LEVEL);
  const [stage, setStage] = useState(0);
  const [timer, setTimer] = useState(settings.SIGNS_TIME);

  const [showIntro, setShowIntro] = useState(true);
  const [showExplanation, setShowExplanation] = useState(false);
  const [showRoundModal, setShowRoundModal] = useState(true);
  const [showSigns, setShowSigns] = useState(false);
  const [showEndGame, setShowEndGame] = useState(false);

  const [finished, setFinished] = useState(false);

  const levelData = useRef(null);
  const stageData = useRef(null);
  const signs = useRef(null);
  const attemptsRef = useRef(null);
  const levelRef = useRef(null);
  const stageRef = useRef(null);

  levelData.current = levels[level];
  stageData.current = levelData.current[stage];
  signs.current = leveledSigns[level];
  attemptsRef.current = attempts;
  levelRef.current = level;
  stageRef.current = stage;

  const showRoundSigns = () => {
    setShowSigns(true);
  };

  useEffect(() => {
    if (!showSigns) return;

    setTimeout(() => {
      const remainingTime = timer - 1;

      if (remainingTime > 0) {
        setTimer(remainingTime);
      } else {
        setShowSigns(false);
        setTimer(settings.SIGNS_TIME);
      }
    }, 1000);
  }, [showSigns, timer]);

  const startRound = (levelValue = 1) => {
    setLevel(levelValue);
    setStage(0);
    setAttempts(settings.ATTEMPTS);

    showRoundSigns();
  };

  const changeStage = () => {
    if (attemptsRef.current <= 0) return setShowEndGame(true);

    if (stageRef.current + 1 < levelData.current.length) {
      setStage((value) => value + 1);
    } else if (levelRef.current === 6) {
      setFinished(true);
    } else {
      setShowRoundModal(true);

      setLevel(levelRef.current + 1);
    }
  };

  const handleAnswer = (isCorrect) => {
    if (!isCorrect) {
      setAttempts((value) => value - 1);
    }

    setTimeout(() => changeStage(), settings.CHANGE_STAGE_DELAY);
  };

  const restartRound = () => {
    setShowEndGame(false);
    setShowRoundModal(true);
    setAttempts(settings.ATTEMPTS);
  };

  const onIntroStart = () => {
    setShowIntro(false);
    setShowExplanation(true);
  };

  const onExplanationStart = () => {
    setShowExplanation(false);
  };

  const onRoundStart = (currentLevel) => {
    setShowRoundModal(false);

    startRound(currentLevel);
  };

  return {
    level,
    stage,
    attempts,
    timer,

    levelData,
    stageData: stageData.current,
    signs: signs.current,

    showIntro,
    showExplanation,
    showRoundModal,
    showSigns,
    showEndGame,

    onIntroStart,
    onExplanationStart,
    onRoundStart,

    handleAnswer,
    restartRound,

    finished,
  };
}

export default useNavigateInMediaSigns;
