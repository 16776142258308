import signs from "./signs";

export default [
  {
    title: "navigatemedia:situations.13.title",
    text: "navigatemedia:situations.13.text",
    answerId: signs.MESSENGER.id,
  },
  {
    title: "navigatemedia:situations.14.title",
    text: "navigatemedia:situations.14.text",
    answerId: signs.PROPAGANDA.id,
  },
  {
    title: "navigatemedia:situations.15.title",
    text: "navigatemedia:situations.15.text",
    answerId: signs.FACT.id,
  },
  {
    title: "navigatemedia:situations.16.title",
    text: "navigatemedia:situations.16.text",
    answerId: signs.FALSE_BALANCE.id,
  },
  {
    title: "navigatemedia:situations.17.title",
    text: "navigatemedia:situations.17.text",
    answerId: signs.HYPE.id,
  },
  {
    title: "navigatemedia:situations.18.title",
    text: "navigatemedia:situations.18.text",
    answerId: signs.MISTAKES.id,
  },
];
