import React, { useRef } from "react";

import { Keyboard } from "../Keyboard";
import { AccessDenied } from "./AccessDenied";
import { AllowedSuccess } from "./AllowedSuccess";
import OutlinedButton from "../../ui/OutlinedButton";

const Answer = ({ password, nextTask, passType, answer, prompt, i18n }) => (
  <div className="game-passwords-phone">
    <div className="game-passwords-phone__title">
      <span className="game-passwords-phone__font game-passwords-phone__font--title font--title">
        {i18n.t("passgame:InputPass")}
      </span>
    </div>

    <input
      type="text"
      readOnly="readOnly"
      autoFocus="autoFocus"
      value={password}
      className="game-passwords-phone__input"
    />

    <span className="game-passwords-phone__font game-passwords-phone__font--subtitle font--text">
      {prompt}
    </span>

    <span className="game-passwords-phone__font game-passwords-phone__font--subtitle font--text">
      {passType}
    </span>

    <div className="game-passwords-phone__text">
      <span
        className="game-passwords-phone__font game-passwords-phone__font--text font--text"
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </div>

    <OutlinedButton
      className="game-passwords-phone__button"
      color="green"
      size="medium"
      onClick={nextTask}
    >
      {i18n.t("common:Buttons.nextTask")}
    </OutlinedButton>
  </div>
);

export const Pass_1 = ({
  i18n,
  task,
  data,
  success,
  denied,
  answer,
  attempt,
  nextTask,
  setAttempt,
  setSuccess,
  setDenied,
  setAnswer,
  audioError,
  audioSuccess,
}) => {
  const inputRef = useRef(null);

  const onKeyboardClickHandler = (value) => {
    const password = data[task - 1].password;
    const passLength = +data[task - 1].passLength;
    const inputValue = inputRef.current.value;

    if (inputValue.length < passLength) {
      inputRef.current.value = inputRef.current.value + value;
    }
    if (inputValue.length + 1 === passLength) {
      if (inputRef.current.value == password) {
        setTimeout(() => {
          setSuccess(true);
        }, 500);
      } else {
        setTimeout(() => {
          setDenied(true);
          setAttempt((attemptPrev) => attemptPrev - 1);
        }, 500);
      }
    }
  };
  return (
    <div className="container-content">
      <div className="container-content-wrapper">
        {denied && !answer && (
          <AccessDenied
            i18n={i18n}
            setDenied={setDenied}
            setAnswer={setAnswer}
            attempt={attempt}
            audioError={audioError}
          />
        )}
        {success && !answer && (
          <AllowedSuccess
            i18n={i18n}
            setAnswer={setAnswer}
            audioSuccess={audioSuccess}
          />
        )}
        {answer && (
          <Answer
            i18n={i18n}
            password={data[task - 1].password}
            nextTask={nextTask}
            passType={i18n.t("passgame:" + task + ".passType")}
            answer={i18n.t("passgame:" + task + ".answer")}
            prompt={i18n.t("passgame:" + task + ".prompt")}
          />
        )}
        {!answer && !success && !denied && (
          <div className="game-passwords-phone">
            <div className="game-passwords-phone__title">
              <span className="game-passwords-phone__font game-passwords-phone__font--title font--title">
                {i18n.t("passgame:InputPass")}
              </span>
            </div>

            <input
              type="text"
              ref={inputRef}
              readOnly="readOnly"
              autoFocus="autoFocus"
              value={""}
              maxLength={data[task - 1].passLength}
              className="game-passwords-phone__input"
            />

            <div className="game-passwords-phone__text">
              <span className="game-passwords-phone__font game-passwords-phone__font--text font--text">
                {i18n.t("passgame:" + task + ".explanation")}
              </span>
            </div>

            <Keyboard
              className="game-passwords-phone__keyboard"
              btnPress={onKeyboardClickHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
};
