import React from "react";
import { Link } from "react-router-dom";

export const EndGame = ({ points, i18n }) => {
  return (
    <div className="game-container final-container">
      <div className="back-arrow">
        <Link to={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </Link>
      </div>
      <div className="container-content">
        <div className="read-fakes">
          <p>{i18n.t("common:Guessed0Statements")}</p>
          <div className="pixel-font">
            <div>
              <span>00</span>
              <span>0{points}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
