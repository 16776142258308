import React from "react";
import { Link } from "react-router-dom";

export const EndGame = ({ score, i18n }) => {
  return (
    <div className="game-container final-container">
      <div className="back-arrow">
        <Link to={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </Link>
      </div>
      <div className="container-content">
        <div className="read-fakes">
          <p>{i18n.t("common:Fakeread")}</p>
          <div className="pixel-font">
            <div>
              <span>00</span>
              <span>0{score}</span>
            </div>
          </div>
        </div>
        <div className="final-animation-block">
          <img
            src="/images/hold-breath/final-bg.svg"
            alt=""
            className="hold-breath-final-bg"
          />
          <img
            src="/images/hold-breath/fire.gif"
            alt=""
            className="fire-animation"
          />
        </div>
      </div>
    </div>
  );
};
