import React, { useState } from "react";

export const Screen_5 = ({ changeScreen, i18n }) => {
  const [animation, setAnimation] = useState(false);

  const checkZipHandler = () => {
    setAnimation(true);
    setTimeout(() => {
      changeScreen("Screen_6");
    }, 1200);
  };

  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block background-1">
        <div className="like-zip">
          <p>{i18n.t("waldogame:Screen5.text1")}</p>
          <div
            className={animation ? "animate scale" : "scale"}
            onMouseDown={checkZipHandler}
            onTouchStart={checkZipHandler}
          >
            <img src="/images/find-waldo/smile.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
