import React from "react"

export const Prompt = ({setPrompt, text}) => {
    return(
        <div className="prompt-block">
            <div className="prompt-block-alert">
                <div className="close-prompt" onClick={() => setPrompt(false)}>
                    <img src="/images/find-waldo/close.svg" alt=""/>
                </div>
                {text}
            </div>
        </div>
    )
}