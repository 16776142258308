import React, { useEffect } from "react";

import OutlinedButton from "../../ui/OutlinedButton";

export const AllowedSuccess = ({ setAnswer, audioSuccess, i18n }) => {
  useEffect(() => {
    audioSuccess.play();
  }, []);

  return (
    <div className="game-passwords-correct">
      <div className="game-passwords-correct__title">
        <span className="game-passwords-correct__font game-passwords-correct__font--title font--title">
          {i18n.t("passgame:Access")}
          <br />
          {i18n.t("passgame:Allow")}
        </span>
      </div>

      <div className="game-passwords-correct__text">
        <span className="game-passwords-correct__font game-passwords-correct__font--text font--text">
          {i18n.t("passgame:CorrectPass")}
        </span>
      </div>

      <OutlinedButton
        className="game-passwords-correct__button"
        color="green"
        size="medium"
        onClick={() => setAnswer(true)}
      >
        {i18n.t("common:Buttons.continue")}
      </OutlinedButton>
    </div>
  );
};
