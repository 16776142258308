import React, { useState } from "react";
import { NextWiget } from "../NextWiget";
import { useTranslation } from "react-i18next";

export const Screen_18 = ({ changeScreen }) => {
  const { i18n } = useTranslation();
  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div
        className="waldo-game__default-block no-padding"
        style={{ overflow: "hidden" }}
      >
        <img
          src="/images/find-waldo/google-street-view.gif"
          alt=""
          className="bg-img"
          style={{ borderRadius: "15px" }}
        />
        <NextWiget changeScreen={changeScreen} screen="Screen_19" />
      </div>
    </div>
  );
};
