import React from "react";
import classNames from "classnames";

import "./text-input.scss";

function TextInput({ className = "", value, onInput, index, placeholder }) {
  return (
    <label className={classNames(className, "text-input")}>
      <div className="text-input__index">
        <span className="text-input__font text-input__font--index font--text">
          {index}.
        </span>
      </div>

      <input
        className="text-input__field"
        type="text"
        placeholder={placeholder}
        value={value}
        onInput={(event) => onInput(event.target.value)}
      />
    </label>
  );
}

export default TextInput;
