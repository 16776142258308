import React from "react";
import classNames from "classnames";

import "./prove-result-popup.scss";

import OutlinedButton from "../../../ui/OutlinedButton";

function ProveResultPopup({
  className = "",
  iconType = "sad",
  color = "green",
  text,
  buttonText,
  onClick,
}) {
  const iconSrc = iconType === "sad" ? "/images/sad.svg" : "/images/happy.svg";

  return (
    <div
      className={classNames(
        className,
        "prove-result-popup",
        `prove-result-popup--color--${color}`,
      )}
    >
      <img className="prove-result-popup__icon" src={iconSrc} alt="icon" />

      <div className="prove-result-popup__text">
        <span className="prove-result-popup__font font--text">{text}</span>
      </div>

      <OutlinedButton
        className="prove-result-popup__button"
        color={color}
        onClick={onClick}
      >
        {buttonText}
      </OutlinedButton>
    </div>
  );
}

export default ProveResultPopup;
