import React from "react";
import Default from "../../../layout/Default";
import OutlinedButton from "../../ui/OutlinedButton";

export const InfoScreen = ({
  info,
  changeScreen,
  infoScreen,
  setInfoScreen,
  isLast,
  i18n,
}) => {
  const nextBtnHandler = () => {
    setInfoScreen((prevInfoScreen) => prevInfoScreen + 1);

    if (isLast) {
      changeScreen("ScoresScreen");
    } else {
      changeScreen("QuestionsScreen");
    }
  };
  return (
    <Default className="game-deepfake-info" title="Original or not">
      <div className="game-deepfake-info__container">
        <div className="game-deepfake-info__wrapper">
          <div className="game-deepfake-info__title">
            <span className="game-deepfake-info__font game-deepfake-info__font--title font--text">
              {i18n.t("deepface:articles." + infoScreen + ".title")}
            </span>
          </div>

          <div className="game-deepfake-info__text">
            <span className="game-deepfake-info__font game-deepfake-info__font--text font--text">
              {i18n.t("deepface:articles." + infoScreen + ".text")}
            </span>
          </div>
        </div>

        <OutlinedButton
          className="game-deepfake-info__button"
          onClick={nextBtnHandler}
        >
          {i18n.t("common:Buttons.next")}
        </OutlinedButton>
      </div>
    </Default>
  );
};
