import React, {useState} from "react"
import {NextWiget} from "../NextWiget";

export const Screen_13 = ({changeScreen, i18n}) => {
    const [pollAnswer, setPollAnswer] = useState(false)

    const pollAnswerHandler = () => {
        setPollAnswer(true)
        setTimeout(() => {
            changeScreen("Screen_14")
        }, 1000)
    }
    return(
        <div className="waldo-game__default-block gradient-type-3 slide-up-block simple-poll">
            <div className="simple-text">{i18n.t("waldogame:Screen13.text1")}</div>
            <div className="simple-poll-block">
                <div className="large-text">{i18n.t("waldogame:Screen13.text2")}<br/>{i18n.t("waldogame:Screen13.text3")}<br/>{i18n.t("waldogame:Screen13.text4")}?</div>
                { pollAnswer ?
                    <img src="/images/find-waldo/poll-answer2.png" alt=""className="pool-img" />
                    : <img src="/images/find-waldo/poll2.png" alt=""className="pool-img" onClick={pollAnswerHandler}/>
                }
            </div>
            
        </div>
    )
}