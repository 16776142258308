import React from "react";
import classNames from "classnames";

import "./message-card.scss";

function MessageCard({
  className = "",
  text,
  imageSrc = "",
  link = "",
  isSent,
}) {
  return (
    <div
      className={classNames(className, "message-card", {
        "message-card--sent": isSent,
      })}
    >
      {imageSrc && (
        <img className="message-card__image" src={imageSrc} alt="image" />
      )}

      {link && (
        <div className="message-card__link">
          <span className="message-card__font message-card__font--link font--text">
            {link}
          </span>
        </div>
      )}

      <div className="message-card__text">
        <span className="message-card__font message-card__font--text font--text">
          {text}
        </span>
      </div>
    </div>
  );
}

export default MessageCard;
