import React from "react";
import classNames from "classnames";

import "./timer.scss";
import settings from "../../config/settings";
import CircleProgress from "./circleProgress/CircleProgress";

function Timer({ className = "", time, totalTime = settings.SIGNS_TIME }) {
  return (
    <div className={classNames(className, "timer")}>
      <CircleProgress
        className="timer__circle"
        progress={(time * 100) / totalTime}
      />

      <div className="timer__time">
        <span className="timer__font font--title">{time}</span>
      </div>
    </div>
  );
}

export default Timer;
