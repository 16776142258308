import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function useLocale(locale) {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);

    document.body.lang = locale;
  }, []);
}

export default useLocale;
