import React, { useRef } from "react";

import useLocale from "../../hooks/useLocale";

import "./styles.scss";

import Default from "../../layout/Default";
import useNavigateInMediaSigns from "./hooks/useNavigateInMediaSigns";
import StartScreen from "./components/screens/StartScreen";
import ExplanationScreen from "./components/screens/ExplanationScreen";
import RoundScreen from "./components/screens/RoundScreen";
import LicenseBundle from "./components/blocks/license/LicenseBundle";

function NavigateInMediaSigns({ match }) {
  const {
    stage,
    timer,
    level,
    attempts,
    levelData,
    stageData,
    signs,
    showIntro,
    onIntroStart,
    showExplanation,
    onExplanationStart,
    showRoundModal,
    finished,
    onRoundStart,
    showSigns,
    handleAnswer,
    restartRound,
    showEndGame,
  } = useNavigateInMediaSigns();

  useLocale(match.params.locale);

  return (
    <Default className="game-navigate-media" title="Navigate in media signs">
      <div className="game-navigate-media__container">
        {showIntro && (
          <StartScreen
            className="game-navigate-media__start"
            onClick={onIntroStart}
          />
        )}

        {showExplanation && (
          <ExplanationScreen
            className="game-navigate-media__explanation"
            onClick={onExplanationStart}
          />
        )}

        {finished && <LicenseBundle className="game-navigate-media__thanks" />}

        {!showIntro && !showExplanation && !finished && (
          <RoundScreen
            className="game-navigate-media__game"
            attempts={attempts}
            level={level}
            stage={stage}
            timer={timer}
            signs={signs}
            stageData={stageData}
            showSigns={showSigns}
            showModal={showRoundModal}
            showEndGame={showEndGame}
            onRoundStart={onRoundStart}
            handleAnswer={handleAnswer}
            restartRound={restartRound}
          />
        )}
      </div>
    </Default>
  );
}

export default NavigateInMediaSigns;
