import React, { useContext } from "react";
import classNames from "classnames";

import "./layout-header.scss";

import { LangPicker } from "../../common/LangPicker/LangPicker";
import routes from "../../constants/routes";
import ReloadButton from "../ui/ReloadButton.jsx";
import { LangContext } from "../../App";

function Header({ className = "" }) {
  const i18n = useContext(LangContext);
  const redirectRoute =
    i18n.language === "en" ? routes.MAIN : routes.MAIN + i18n.language;

  return (
    <div className={classNames(className, "layout-header")}>
      <div className="layout-header__wrapper layout-header__wrapper--logo">
        <a href={redirectRoute}>
          <img
            className="layout-header__logo"
            src="/images/logo.svg"
            alt="logo"
          />
        </a>

        <div className="layout-header__title">
          <span className="layout-header__font layout-header__font--title font--text">
            exhibition on media literacy
          </span>
        </div>
      </div>

      <div className="layout-header__wrapper layout-header__wrapper--buttons">
        <ReloadButton className="layout-header__button" />

        <LangPicker theme="dark" />
      </div>
    </div>
  );
}

export default Header;
