import React, { useContext, useState } from "react";
import { StartGame } from "./screens/StartGame";
import { Pass_1 } from "./screens/Pass_1";

import "./style.scss";

import data from "./data.json";
import { EndGame } from "./screens/EndGame";
import { LangContext } from "../../App";
import useLocale from "../../hooks/useLocale";
import LifesHeader from "../blocks/LifesHeader";
import Default from "../../layout/Default";
import ThankYou from "../../common/ThankYou/ThankYou";

export const Passwords = ({ match }) => {
  const [screen, setScreen] = useState("StartGame");
  const [success, setSuccess] = useState(false);
  const [denied, setDenied] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [task, setTask] = useState(1);
  const [attempt, setAttempt] = useState(5);
  const [score, setScore] = useState(data.length);
  const [isThanks, setIsThanks] = useState(false);

  const changeScreen = (screen) => setScreen(screen);

  const i18n = useContext(LangContext);

  const nextTask = () => {
    if (data.length == task) {
      changeScreen("EndGame");
      return false;
    }
    if (attempt === 0) {
      setScore((prevScore) => prevScore - 1);
    }
    setSuccess(false);
    setAnswer(false);
    setAttempt(5);
    setTask((prevTask) => prevTask + 1);
  };

  const audioSuccess = new Audio("/ctrl/sounds/success.mp3");
  const audioError = new Audio("/ctrl/sounds/error.mp3");

  useLocale(match.params.locale);

  return (
    <Default className="game-passwords" title="Unlock my phone">
      {isThanks ? (
        <ThankYou className="game-passwords-result__thanks" />
      ) : (
        <div className="game-passwords__container">
          <LifesHeader
            className="game-passwords__header"
            currentProgress={task}
            totalProgress={7}
            lifes={attempt}
          />
          {screen === "StartGame" && (
            <StartGame changeScreen={changeScreen} i18n={i18n} />
          )}
          {screen === "Pass_1" && (
            <Pass_1
              i18n={i18n}
              task={task}
              attempt={attempt}
              answer={answer}
              success={success}
              denied={denied}
              data={data}
              audioSuccess={audioSuccess}
              audioError={audioError}
              setTask={setTask}
              nextTask={nextTask}
              setAttempt={setAttempt}
              setAnswer={setAnswer}
              setDenied={setDenied}
              setSuccess={setSuccess}
              changeScreen={changeScreen}
            />
          )}
          {screen === "EndGame" && (
            <EndGame score={score} i18n={i18n} onClick={setIsThanks} />
          )}
        </div>
      )}
    </Default>
  );
};
