import React, { useContext, useState } from "react";
import classNames from "classnames";
import Lottie from "react-lottie";

import "swiper/swiper.scss";
import "./styles.scss";

import animationData from "../../assets/conveyor.json";

import useConveyorSlider from "./hooks/useConveyorSlider.js";
import useConveyorCore from "./hooks/useConveyorCore";
import { LangContext } from "../../App";

import Default from "../../layout/Default";
import NewsCard from "./components/cards/NewsCard";
import NewsLabel from "./components/cards/NewsLabel";
import LifesLabel from "../blocks/LifesLabel";
import LevelBlock from "./components/blocks/LevelBlock";
import ThankYou from "../../common/ThankYou/ThankYou";
import useLocale from "../../hooks/useLocale";
import ConveyorGameOver from "./components/popups/ConveyorGameOver";
import Timer from "../NavigateInMediaSigns/components/blocks/Timer";
import settings from "./config/settings";

function Conveyor({ match }) {
  const i18n = useContext(LangContext);
  const [selectedLabelId, setSelectedLabelId] = useState(undefined);

  const {
    answers,
    handleAnswer,
    activeIndex,
    slides,
    levelLabels,
    attempts,
    conveyorPaused,
    showLabels,
    explanationIndex,
    showPopup,
    restartGame,
    onExplanationClick,
    timer,
    showTimer,
  } = useConveyorCore();
  const { animating } = useConveyorSlider();

  const isSlideActive = (index) => {
    return index === activeIndex;
  };

  const onHandleAnswer = (id) => {
    if (animating || explanationIndex) return;

    setSelectedLabelId(id);
    handleAnswer(id);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useLocale(match.params.locale);

  return (
    <Default className="game-conveyor" title="Conveyor" isHidden>
      {showPopup && (
        <ConveyorGameOver
          className="game-conveyor__popup"
          onClick={restartGame}
        />
      )}

      <div className="game-conveyor__container">
        <div className="game-conveyor__row">
          <LifesLabel className="game-conveyor__lifes" lifes={attempts} />
          {showTimer && (
            <Timer
              className="game-conveyor__timer"
              time={timer}
              totalTime={settings.ANSWER_TIME / 1000}
            />
          )}
        </div>

        <div className="game-conveyor__wrapper">
          <div className="game-conveyor__slider swiper">
            <div className="swiper-wrapper">
              {slides.map((slide, index) => {
                switch (true) {
                  case slide.type === "level":
                    return (
                      <LevelBlock
                        key={index}
                        className="game-conveyor__level swiper-slide"
                        value={slide.value}
                      />
                    );
                  case slide.type === "thanks":
                    return (
                      <div
                        key={index}
                        className="game-conveyor__thanks-wrapper swiper-slide"
                      >
                        <ThankYou className="game-conveyor__thanks " />
                      </div>
                    );
                  default:
                    return (
                      <NewsCard
                        className="game-conveyor__card swiper-slide"
                        key={index}
                        authorName={i18n.t(slide.authorName)}
                        authorNick={i18n.t(slide.authorNick)}
                        authorImage={slide.authorImage}
                        imageSrc={slide.imageSrc}
                        text={i18n.t(slide.text)}
                        explanation={i18n.t(slide.explanation)}
                        labelId={selectedLabelId}
                        isActive={isSlideActive(index)}
                        isHidden={index < activeIndex}
                        isAnswered={answers[index] !== undefined}
                        isCorrect={answers[index]}
                        onExplanationClick={onExplanationClick}
                        showExplanation={explanationIndex === index}
                      />
                    );
                }
              })}
            </div>
          </div>

          <Lottie
            title="lottie"
            id="lottie"
            className="game-conveyor__video"
            speed={0.7}
            isClickToPauseDisabled={true}
            options={defaultOptions}
            isPaused={conveyorPaused}
          />
        </div>

        <div
          className={classNames("game-conveyor__labels", {
            "game-conveyor__labels--hidden": !showLabels,
          })}
        >
          {levelLabels.map((label) => (
            <NewsLabel
              key={label.id}
              id={label.id}
              name={i18n.t(label.name)}
              color={label.color}
              icon={label.icon}
              setLabel={onHandleAnswer}
            />
          ))}
        </div>
      </div>
    </Default>
  );
}

export default Conveyor;
