import signs from "./signs";

export default [
  {
    title: "navigatemedia:situations.7.title",
    text: "navigatemedia:situations.7.text",
    answerId: signs.PREJUDICE.id,
  },
  {
    title: "navigatemedia:situations.8.title",
    text: "navigatemedia:situations.8.text",
    answerId: signs.BOT.id,
  },
  {
    title: "navigatemedia:situations.9.title",
    text: "navigatemedia:situations.9.text",
    answerId: signs.TROLL.id,
  },
  {
    title: "navigatemedia:situations.10.title",
    text: "navigatemedia:situations.10.text",
    answerId: signs.CONSPIRACY.id,
  },
  {
    title: "navigatemedia:situations.11.title",
    text: "navigatemedia:situations.11.text",
    answerId: signs.WHATABOUTISM.id,
  },
  {
    title: "navigatemedia:situations.12.title",
    text: "navigatemedia:situations.12.text",
    answerId: signs.COMMENTS.id,
  },
];
