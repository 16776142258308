import { ReactComponent as Icon1 } from "../../../assets/svg/conveyor/labels/1.svg";
import { ReactComponent as Icon2 } from "../../../assets/svg/conveyor/labels/2.svg";
import { ReactComponent as Icon3 } from "../../../assets/svg/conveyor/labels/3.svg";
import { ReactComponent as Icon4 } from "../../../assets/svg/conveyor/labels/4.svg";
import { ReactComponent as Icon5 } from "../../../assets/svg/conveyor/labels/5.svg";
import { ReactComponent as Icon6 } from "../../../assets/svg/conveyor/labels/6.svg";
import { ReactComponent as Icon7 } from "../../../assets/svg/conveyor/labels/7.svg";

export const labelsDictionary = {
  1: {
    id: 1,
    name: "conveyor:labels.1",
    color: "#E8FE87",
    icon: Icon1,
  },
  2: {
    id: 2,
    name: "conveyor:labels.2",
    color: "#F7CDD6",
    icon: Icon2,
  },
  3: {
    id: 3,
    name: "conveyor:labels.3",
    color: "#FFFFFF",
    icon: Icon3,
  },
  4: {
    id: 4,
    name: "conveyor:labels.4",
    color: "#F7CDD6",
    icon: Icon4,
  },
  5: {
    id: 5,
    name: "conveyor:labels.5",
    color: "#E8FE87",
    icon: Icon5,
  },
  6: {
    id: 6,
    name: "conveyor:labels.6",
    color: "#9D9CF8",
    icon: Icon6,
  },
  7: {
    id: 7,
    name: "conveyor:labels.7",
    color: "#BFBFBF",
    icon: Icon7,
  },
};

export default {
  1: [labelsDictionary[1], labelsDictionary[2]],
  2: [
    labelsDictionary[3],
    labelsDictionary[4],
    labelsDictionary[5],
    labelsDictionary[6],
  ],
  3: [
    labelsDictionary[3],
    labelsDictionary[4],
    labelsDictionary[5],
    labelsDictionary[6],
    labelsDictionary[7],
  ],
};
