import signs from "./signs";

export default [
  {
    title: "navigatemedia:situations.19.title",
    text: "navigatemedia:situations.19.text",
    answerId: signs.JEANS.id,
  },
  {
    title: "navigatemedia:situations.20.title",
    text: "navigatemedia:situations.20.text",
    answerId: signs.ROUTE.id,
  },
  {
    title: "navigatemedia:situations.21.title",
    text: "navigatemedia:situations.21.text",
    answerId: signs.FUN.id,
  },
  {
    title: "navigatemedia:situations.22.title",
    text: "navigatemedia:situations.22.text",
    answerId: signs.DOCUMENTARY.id,
  },
  {
    title: "navigatemedia:situations.23.title",
    text: "navigatemedia:situations.23.text",
    answerId: signs.FEAR.id,
  },
  {
    title: "navigatemedia:situations.24.title",
    text: "navigatemedia:situations.24.text",
    answerId: signs.AUDIENCE.id,
  },
];
