import React, { useRef, useState } from "react";
import { NextWiget } from "../NextWiget";

export const Screen_24 = ({ changeScreen, i18n }) => {
  const [form, setForm] = useState(false);
  const [inputAnimate, setInputAnimate] = useState(false);
  const textInput = useRef(null);

  const checkAnswer = () => {
    if (textInput.current.value.toLowerCase() === "oeno") {
      changeScreen("Finish");
    } else {
      setInputAnimate(true);
      setTimeout(() => {
        setInputAnimate(false);
      }, 500);
    }
  };

  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block no-padding">
        {form ? (
          <img
            src="/images/find-waldo/bg_9_2.jpg"
            alt=""
            className="bg-img"
            style={{ borderRadius: "15px", minHeight: "600px" }}
          />
        ) : (
          <img
            src="/images/find-waldo/bg_9.jpg"
            alt=""
            className="bg-img"
            style={{ borderRadius: "15px", minHeight: "600px" }}
          />
        )}
        {form && (
          <div className="where-brant-form-wrapper">
            <div className="where-brant-form">
              <div className="where-brant-form__body">
                <p>{i18n.t("waldogame:Screen24.text1")}</p>
                <input
                  type="text"
                  placeholder={i18n.t("waldogame:Screen24.placeholder")}
                  ref={textInput}
                  className={inputAnimate && "animate"}
                />
              </div>
              <div className="submit-answer" onClick={checkAnswer}>
                {i18n.t("waldogame:Screen24.text2")}
              </div>
            </div>
          </div>
        )}
        <div className="next-wiget no-z-index" onClick={() => setForm(true)}>
          <img src="/images/find-waldo/next-arr.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

// <NextWiget changeScreen={changeScreen} screen="Screen_12" />
