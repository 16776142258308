import React, { useState } from "react";

import ThankYou from "../../../common/ThankYou/ThankYou";
import OutlinedButton from "../../ui/OutlinedButton";
import Default from "../../../layout/Default";

export const ScoresScreen = ({ points, i18n }) => {
  const [isThanks, setIsThanks] = useState(false);

  return (
    <Default className="game-deepfake-result" title="Original or not">
      {isThanks ? (
        <ThankYou className="game-deepfake-result__thanks" />
      ) : (
        <>
          <div className="game-deepfake-result__container">
            <div className="game-deepfake-result__content">
              <div className="game-deepfake-result__title">
                <span className="game-deepfake-result__font font--title">
                  {points}/10
                </span>
              </div>
            </div>

            <OutlinedButton
              className="game-deepfake-result__button"
              color="green"
              onClick={() => setIsThanks(true)}
            >
              {i18n.t("common:Buttons.next")}
            </OutlinedButton>
          </div>
        </>
      )}
    </Default>
  );
};
