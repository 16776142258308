export default {
  1: {
    id: 1,
    text: "provemewrong:levels.1.questions.1.text",
    sharing: {
      text: "provemewrong:levels.1.questions.1.sharing.text",
      imageSrc: "/images/prove-me-wrong/level-1-q-1.png",
      link: "bestfloats.fish",
    },
    answers: [
      {
        questionId: 2,
        text: "provemewrong:levels.1.questions.1.answers.1",
        value: 2,
      },
      {
        questionId: 2,
        text: "provemewrong:levels.1.questions.1.answers.2",
        value: 2,
      },
    ],
  },
  2: {
    id: 2,
    text: "provemewrong:levels.1.questions.2.text",
    answers: [
      {
        questionId: 3,
        text: "provemewrong:levels.1.questions.2.answers.1",
        value: 1,
      },
      {
        questionId: 3,
        text: "provemewrong:levels.1.questions.2.answers.2",
        value: 0,
      },
    ],
  },
  3: {
    id: 3,
    text: "provemewrong:levels.1.questions.3.text",
    answers: [
      {
        questionId: 4,
        text: "provemewrong:levels.1.questions.3.answers.1",
        value: 1,
      },
      {
        questionId: 4,
        text: "provemewrong:levels.1.questions.3.answers.2",
        value: -1,
      },
    ],
  },
  4: {
    id: 4,
    text: "provemewrong:levels.1.questions.4.text",
    answers: [
      {
        questionId: 6,
        text: "provemewrong:levels.1.questions.4.answers.1",
        value: 1,
      },
      {
        questionId: 5,
        text: "provemewrong:levels.1.questions.4.answers.2",
        value: -1,
      },
    ],
  },
  5: {
    id: 5,
    text: "provemewrong:levels.1.questions.5.text",
    answers: [
      {
        questionId: 6,
        text: "provemewrong:levels.1.questions.5.answers.1",
        value: 1,
      },
    ],
  },
  6: {
    id: 6,
    text: "provemewrong:levels.1.questions.6.text",
    answers: [
      {
        questionId: 9,
        text: "provemewrong:levels.1.questions.6.answers.1",
        value: 1,
      },
      {
        questionId: 7,
        text: "provemewrong:levels.1.questions.6.answers.2",
        value: -1,
      },
    ],
  },
  7: {
    id: 7,
    text: "provemewrong:levels.1.questions.7.text",
    answers: [
      {
        questionId: 8,
        text: "provemewrong:levels.1.questions.7.answers.1",
        value: -1,
      },
      {
        questionId: 9,
        text: "provemewrong:levels.1.questions.7.answers.2",
        value: 1,
      },
    ],
  },
  8: {
    id: 8,
    text: "provemewrong:levels.1.questions.8.text",
    restart: {
      id: 9,
      text: "provemewrong:popups.noTrust",
      buttonText: "provemewrong:popups.again",
    },
  },
  9: {
    id: 9,
    text: "provemewrong:levels.1.questions.9.text",
    answers: [
      {
        questionId: 10,
        text: "provemewrong:levels.1.questions.9.answers.1",
        value: 1,
      },
    ],
  },
  10: {
    id: 10,
    text: "provemewrong:levels.1.questions.10.text",
    answers: [
      {
        questionId: 12,
        text: "provemewrong:levels.1.questions.10.answers.1",
        value: 1,
      },
      {
        questionId: 11,
        text: "provemewrong:levels.1.questions.10.answers.2",
        value: 0,
      },
    ],
  },
  11: {
    id: 11,
    text: "provemewrong:levels.1.questions.11.text",
    answers: [
      {
        questionId: 12,
        text: "provemewrong:levels.1.questions.11.answers.1",
        value: 1,
      },
    ],
  },
  12: {
    id: 12,
    text: "provemewrong:levels.1.questions.12.text",
    answers: [
      {
        questionId: 15,
        text: "provemewrong:levels.1.questions.12.answers.1",
        value: 1,
      },
      {
        questionId: 13,
        text: "provemewrong:levels.1.questions.12.answers.2",
        value: -1,
      },
    ],
  },
  13: {
    id: 13,
    text: "provemewrong:levels.1.questions.13.text",
    answers: [
      {
        questionId: 14,
        text: "provemewrong:levels.1.questions.13.answers.1",
      },
    ],
  },
  14: {
    id: 14,
    text: "provemewrong:levels.1.questions.6.text",
    restart: {
      id: 15,
      text: "provemewrong:popups.absoluteNoTrust",
      buttonText: "provemewrong:popups.oneMore",
    },
  },
  15: {
    id: 15,
    text: "provemewrong:levels.1.questions.15.text",
    answers: [
      {
        questionId: 16,
        text: "provemewrong:levels.1.questions.15.answers.1",
        value: 1,
      },
      {
        questionId: 16,
        text: "provemewrong:levels.1.questions.15.answers.2",
        value: 0,
      },
    ],
  },
  16: {
    id: 16,
    text: "provemewrong:levels.1.questions.16.text",
    answers: [
      {
        isFinal: true,
        text: "provemewrong:levels.1.questions.16.answers.1",
      },
    ],
  },
};
