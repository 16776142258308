import React, { useState } from "react";

export const Screen_8 = ({ changeScreen, i18n }) => {
  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block gradient-type-1">
        <div className="simple-text">
          <pre style={{ fontFamily: "NeueMachina-Regular" }}>
            {i18n.t("waldogame:Screen8.text1")}
          </pre>
        </div>
        <div className="hashtag" onClick={() => changeScreen("Screen_9")}>
          #mawsonpeak
        </div>
      </div>
    </div>
  );
};
