export default [
  // {
  //   title: "Українська",
  //   code: "ua",
  // },
  {
    title: "En",
    code: "en",
  },
  {
    title: "El",
    code: "el",
  },
  {
    title: "Tr",
    code: "tr",
  },
  {
    title: "Hr",
    code: "hr",
  },
  {
    title: "Bs",
    code: "bs",
  },
  {
    title: "Sr",
    code: "sr",
  },
  {
    title: "Ro",
    code: "ro",
  },
  {
    title: "Mk",
    code: "mk",
  },
  {
    title: "De",
    code: "de",
  },
];
