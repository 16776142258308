import React from "react";
import classNames from "classnames";

import "./chat-responses.scss";

function ChatResponses({ className = "", responses, selectResponse, i18n }) {
  return (
    <div className={classNames(className, "chat-responses")}>
      {responses.map((item, index) => (
        <button
          className="chat-responses__item"
          key={index}
          onClick={() => selectResponse(item)}
        >
          <span className="chat-responses__font font--text">
            {i18n.t(item.text)}
          </span>
        </button>
      ))}
    </div>
  );
}

export default ChatResponses;
