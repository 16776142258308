import React from "react";
import { useTranslation } from "react-i18next";

export const TestScreen = ({
  data,
  testQuestion,
  setTestQuestion,
  changeScreen,
}) => {
  const { i18n } = useTranslation();
  const chooseAnswerHandler = (e) => {
    if (testQuestion == data.length) {
      setTimeout(() => {
        changeScreen("SongScreen");
        return false;
      }, 500);
    }
    const _target = e.target;
    _target.classList.add("selected");
    setTimeout(() => {
      setTestQuestion((prevTestQuestion) => prevTestQuestion + 1);
      _target.classList.remove("selected");
    }, 500);
  };
  return (
    <div className="game-container test-screen">
      <div className="back-arrow nav-panel">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
        <div className="points">
          <span className="pixel-font">
            {testQuestion} / {data.length}
          </span>
        </div>
      </div>
      <div className="container-content">
        <div className="container-content-wrapper">
          <div className="container-title question-title">
            {data[testQuestion - 1].question}
          </div>
          {data[testQuestion - 1].answers.map((key, index) => (
            <p
              onClick={(e) => chooseAnswerHandler(e)}
              className="question-content test-question"
            >
              {data[testQuestion - 1].answers[index]}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
