import React from "react";
import { Link } from "react-router-dom";
import { LangPicker } from "../../../common/LangPicker/LangPicker";
import { useTranslation } from "react-i18next";

export const StartGame = ({ changeScreen }) => {
  const { i18n } = useTranslation();

  return (
    <div className="game-container take-quiz-game">
      <div className="back-arrow nav-panel">
        <Link to={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </Link>
        <LangPicker />
      </div>
      <div className="container-content">
        <div className="container-content-wrapper">
          <div className="animate-center-box">
            <p className="text-margin">Take a moment, take a quiz</p>
            <div className="container-title small-screen">
              <img
                src="/images/take-quiz/tunnel.gif"
                alt=""
                className="tunnel-gif"
              />
              <div className="text-frame frame-1">
                Зупиніться
                <br />
                на хвилинку,
              </div>
              <div className="text-frame frame-2">
                щоб
                <br />
                дізнатися,
              </div>
              <div className="text-frame frame-3 green-text">
                яка ви
                <br />
                поп-пісня?
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-wrapper">
        <div
          className="game-btn start-btn hover-scale"
          onClick={() => changeScreen("FbScreen")}
        >
          Розпочати
        </div>
      </div>
    </div>
  );
};
