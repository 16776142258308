import React from "react";
import classNames from "classnames";

import "./progress-label.scss";

function ProgressLabel({ className = "", current, total }) {
  return (
    <div className={classNames(className, "progress-label")}>
      <span className="progress-label__font">
        {current}/{total}
      </span>
    </div>
  );
}

export default ProgressLabel;
