import { labelsDictionary } from "../config/labels";

export default [
  {
    id: 1,
    type: "news",
    authorName: "conveyor:levels.2.news.1.authorName",
    authorNick: "conveyor:levels.2.news.1.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q1ABCNews.jpg",
    text: "conveyor:levels.2.news.1.text",
    explanation: "conveyor:levels.2.news.1.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q1.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
  {
    id: 2,
    type: "news",
    authorName: "conveyor:levels.2.news.2.authorName",
    authorNick: "conveyor:levels.2.news.2.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q2Biden.jpeg",
    text: "conveyor:levels.2.news.2.text",
    explanation: "conveyor:levels.2.news.2.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q2.jpeg",
    labelIcon: labelsDictionary[4].icon,
    labelId: labelsDictionary[4].id,
  },
  {
    id: 3,
    type: "news",
    authorName: "conveyor:levels.2.news.3.authorName",
    authorNick: "conveyor:levels.2.news.3.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q3Astronaut.jpeg",
    text: "conveyor:levels.2.news.3.text",
    explanation: "conveyor:levels.2.news.3.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q3.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 4,
    type: "news",
    authorName: "conveyor:levels.2.news.4.authorName",
    authorNick: "conveyor:levels.2.news.4.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q4ABCFake.png",
    text: "conveyor:levels.2.news.4.text",
    explanation: "conveyor:levels.2.news.4.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q4.jpeg",
    labelIcon: labelsDictionary[6].icon,
    labelId: labelsDictionary[6].id,
  },
  {
    id: 5,
    type: "news",
    authorName: "conveyor:levels.2.news.5.authorName",
    authorNick: "conveyor:levels.2.news.5.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q5BBC.jpg",
    text: "conveyor:levels.2.news.5.text",
    explanation: "conveyor:levels.2.news.5.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q5.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
  {
    id: 6,
    type: "news",
    authorName: "conveyor:levels.2.news.6.authorName",
    authorNick: "conveyor:levels.2.news.6.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q6Biden.jpg",
    text: "conveyor:levels.2.news.6.text",
    explanation: "conveyor:levels.2.news.6.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q6.jpeg",
    labelIcon: labelsDictionary[6].icon,
    labelId: labelsDictionary[6].id,
  },
  {
    id: 7,
    type: "news",
    authorName: "conveyor:levels.2.news.7.authorName",
    authorNick: "conveyor:levels.2.news.7.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q7NASA.jpg",
    text: "conveyor:levels.2.news.7.text",
    explanation: "conveyor:levels.2.news.7.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q7.jpeg",
    labelIcon: labelsDictionary[4].icon,
    labelId: labelsDictionary[4].id,
  },
  {
    id: 8,
    type: "news",
    authorName: "conveyor:levels.2.news.8.authorName",
    authorNick: "conveyor:levels.2.news.8.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q8NASAFAKE.jpeg",
    text: "conveyor:levels.2.news.8.text",
    explanation: "conveyor:levels.2.news.8.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q8.jpeg",
    labelIcon: labelsDictionary[6].icon,
    labelId: labelsDictionary[6].id,
  },
  {
    id: 9,
    type: "news",
    authorName: "conveyor:levels.2.news.9.authorName",
    authorNick: "conveyor:levels.2.news.9.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q9WSJ.jpg",
    text: "conveyor:levels.2.news.9.text",
    explanation: "conveyor:levels.2.news.9.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q9.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
  {
    id: 10,
    type: "news",
    authorName: "conveyor:levels.2.news.10.authorName",
    authorNick: "conveyor:levels.2.news.10.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q10VSG.jpeg",
    text: "conveyor:levels.2.news.10.text",
    explanation: "conveyor:levels.2.news.10.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q10.jpeg",
    labelIcon: labelsDictionary[6].icon,
    labelId: labelsDictionary[6].id,
  },
  {
    id: 11,
    type: "news",
    authorName: "conveyor:levels.2.news.11.authorName",
    authorNick: "conveyor:levels.2.news.11.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q11SeekTruth.jpeg",
    text: "conveyor:levels.2.news.11.text",
    explanation: "conveyor:levels.2.news.11.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q11.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 12,
    type: "news",
    authorName: "conveyor:levels.2.news.12.authorName",
    authorNick: "conveyor:levels.2.news.12.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q12Bonanza.jpeg",
    text: "conveyor:levels.2.news.12.text",
    explanation: "conveyor:levels.2.news.12.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q12.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 13,
    type: "news",
    authorName: "conveyor:levels.2.news.13.authorName",
    authorNick: "conveyor:levels.2.news.13.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q13DiCaprio.jpeg",
    text: "conveyor:levels.2.news.13.text",
    explanation: "conveyor:levels.2.news.13.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q13.jpeg",
    labelIcon: labelsDictionary[4].icon,
    labelId: labelsDictionary[4].id,
  },
  {
    id: 14,
    type: "news",
    authorName: "conveyor:levels.2.news.14.authorName",
    authorNick: "conveyor:levels.2.news.14.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q14Plandemic.avif",
    text: "conveyor:levels.2.news.14.text",
    explanation: "conveyor:levels.2.news.14.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q14.jpeg",
    labelIcon: labelsDictionary[5].icon,
    labelId: labelsDictionary[5].id,
  },
  {
    id: 15,
    type: "news",
    authorName: "conveyor:levels.2.news.15.authorName",
    authorNick: "conveyor:levels.2.news.15.authorNick",
    authorImage: "/images/conveyor/author/level-2/L2Q15.png",
    text: "conveyor:levels.2.news.15.text",
    explanation: "conveyor:levels.2.news.15.explanation",
    imageSrc: "/images/conveyor/news/level-2/Q15.jpeg",
    labelIcon: labelsDictionary[3].icon,
    labelId: labelsDictionary[3].id,
  },
];
