import React, { useState } from "react";
import { NextWiget } from "../NextWiget";

export const Screen_11 = ({ changeScreen, i18n }) => {
  return (
    <div className="waldo-game">
      <div className="back-arrow">
        <a href={`/${i18n.language}`}>
          <img
            src="/images/game1/arrow-back.svg"
            alt=""
            className="hover-scale"
          />
        </a>
      </div>
      <div className="waldo-game__default-block no-padding scroll-y">
        <img src={i18n.t("waldogame:Screen11.bg")} alt="" className="bg-img" />
        <NextWiget changeScreen={changeScreen} screen="Screen_12" />
      </div>
    </div>
  );
};
