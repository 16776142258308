import React, { useContext } from "react";
import classNames from "classnames";

import "./image-input.scss";

import { LangContext } from "../../../../../../App.js";

function ImageInput({ className = "", value, onChange }) {
  const i18n = useContext(LangContext);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleInput = async (event) => {
    if (!event.target || !event.target.files || !event.target.files[0]) return;

    const base = await toBase64(event.target.files[0]);

    onChange(base);
  };
  return (
    <label className={classNames(className, "image-input")}>
      <input
        type="file"
        accept="image/*"
        className="image-input__field"
        onChange={handleInput}
      />

      {value ? (
        <img className="image-input__image" src={value} alt="uploaded-image" />
      ) : (
        <div className="image-input__empty">
          <img
            className="image-input__icon"
            src="/images/camera.svg"
            alt="photo"
          />
          <span className="image-input__font font--text">
            {i18n.t("navigatemedia:upload")}
          </span>
        </div>
      )}
    </label>
  );
}

export default ImageInput;
